var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ContentHeader"},[_c('div',{staticClass:"HeaderAll"},[_vm._t("titlebreadcrumb"),_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),_vm._l((_vm.breadcrumb),function(item,i){return _c('a-breadcrumb-item',{key:i},[(i + 1 < _vm.breadcrumb.length)?_c('router-link',{attrs:{"to":{ name: item.nameroute }}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])],1)})],2)],2),_vm._t("benefitheader")],2),_vm._t("menuall"),_c('div',{staticClass:"allcard",staticStyle:{"border-top-left-radius":"0px","height":"-webkit-fill-available","position":"absolute","width":"100%"}},[_c('simplebar',{staticClass:"simplebarcustom",attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',{staticClass:"claimdetail"},[_c('a-skeleton',{attrs:{"loading":_vm.loading}},[_c('a-row',{attrs:{"gutter":[24, 24]}},[_c('div',{ref:"infoBoxfirst",staticStyle:{"float":"left","width":"100%"}},[_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('div',{staticClass:"claimcard",style:(`min-height:${_vm.infoBoxfirst}px`)},[_c('h2',[_vm._v("Ketentuan Dasar Klaim:")]),_c('a-row',[_c('a-col',{attrs:{"lg":12}},_vm._l((_vm.ketentuan_claim.filter(
                          (key, i) => i < 2
                        )),function(item,i){return _c('div',{key:i,staticClass:"sec-data-pasien"},[_c('div',[_c('label',[_vm._v(_vm._s(item.ketentuan_umum))]),_c('p',[_vm._v(_vm._s(item.standar_andika))])])])}),0),_c('a-col',{attrs:{"lg":12}},_vm._l((_vm.ketentuan_claim.filter(
                          (key, i) => i >= 2
                        )),function(item,i){return _c('div',{key:i,staticClass:"sec-data-pasien"},[_c('div',[_c('label',[_vm._v(_vm._s(item.ketentuan_umum))]),_c('p',[_vm._v(_vm._s(item.standar_andika))])])])}),0)],1)],1)]),_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('div',{staticClass:"claimcard",style:(`min-height:${_vm.infoBoxfirst}px`)},[_c('h2',[_vm._v("Ketentuan Dasar Kepesertaan:")]),_c('a-row',{attrs:{"gutter":[15, 15]}},[_c('a-col',{attrs:{"lg":12}},_vm._l((_vm.ketentuan_dasar_peserta.filter(
                          (key, i) => i <= 1
                        )),function(item,i){return _c('div',{key:i,staticClass:"sec-data-pasien"},[_c('div',[_c('label',[_vm._v(_vm._s(item.ketentuan_umum))]),_c('p',[_vm._v(_vm._s(item.standar_andika))])])])}),0),_c('a-col',{attrs:{"lg":12}},_vm._l((_vm.ketentuan_dasar_peserta.filter(
                          (key, i) => i >= 2
                        )),function(item,i){return _c('div',{key:i,staticClass:"sec-data-pasien"},[_c('div',[_c('label',[_vm._v(_vm._s(item.ketentuan_umum))]),_c('p',[_vm._v(_vm._s(item.standar_andika))])])])}),0)],1)],1)])],1)])],1)],1)])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }