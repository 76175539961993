var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ContentHeader"},[_c('div',{staticClass:"HeaderAll"},[_vm._t("titlebreadcrumb"),_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),_vm._l((_vm.breadcrumb),function(item,i){return _c('a-breadcrumb-item',{key:i},[(i + 1 < _vm.breadcrumb.length)?_c('router-link',{attrs:{"to":{ name: item.nameroute }}},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))])],1)})],2)],2),_vm._t("benefitheader")],2),_vm._t("menuall"),_c('div',{staticClass:"allcard",staticStyle:{"border-top-left-radius":"0px","height":"-webkit-fill-available","position":"absolute","width":"100%"}},[_c('simplebar',{staticClass:"simplebarcustom",attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"menubenefit"},[(_vm.tab.length > 0)?_c('ul',_vm._l((_vm.tab),function(item,i){return _c('li',{key:i},[_c('router-link',{class:_vm.$route.query.plan == item.sheet ? 'activebenefit' : '',attrs:{"to":{ name: 'ListBenefit', query: { plan: item.sheet } }}},[_vm._v(_vm._s(item.sheet))])],1)}),0):_vm._e()]),_c('div',{staticClass:"ContentHeaderSearch contentsearchpt"},[_c('a-input-search',{staticStyle:{"width":"327px"},attrs:{"placeholder":"Search Here","allowClear":""},on:{"search":_vm.onSearch}})],1)]),_c('a-table',{attrs:{"rowKey":(record) => record.rowid,"columns":_vm.column,"dataSource":_vm.FilterData,"loading":_vm.loading,"pagination":{
          pageSize: 10000,

          showTotal: (total, range) =>
            range[0] + '-' + range[1] + ' of ' + total,
        },"scroll":{ x: _vm.widthtable },"rowClassName":_vm.customRow},on:{"change":_vm.handlePageChange},scopedSlots:_vm._u([_vm._l((_vm.rawcolumn),function(item,i){return {key:`scopeCustom-${i}`,fn:function(text, record, ii){return _c('div',{key:i},[(
              ii ==
              _vm.FilterData.map((key) => key.manfaat_plan).indexOf(
                'Maks. Limit per tahun'
              )
            )?_c('div',{staticStyle:{"font-weight":"bolder"}},[_vm._v(" "+_vm._s(text)+" ")]):_c('div',[_vm._v(_vm._s(text))])])}}})],null,true)},[_vm._l((_vm.rawcolumn),function(item,i){return _c('span',{key:i,attrs:{"slot":`customTitle-${i}`},slot:`customTitle-${i}`},[(i == 0 || i == 1 || i == 2)?_c('span',[_vm._v(_vm._s(_vm.Capital(item[1].replace("_", " "))))]):_vm._e(),(i > 2)?_c('span',[_c('span',{staticClass:"iconcolumn"},[(item[1].split(' ')[0] === 'RI')?_c('img',{attrs:{"src":require('@/assets/img/icon/ri.svg')}}):_vm._e(),(item[1].split(' ')[0] == 'RJ')?_c('img',{attrs:{"src":require('@/assets/img/icon/rj.svg')}}):_vm._e(),(item[1].split(' ')[0] == 'RG')?_c('img',{attrs:{"src":require('@/assets/img/icon/rg.svg')}}):_vm._e(),(item[1].split(' ')[0] == 'KM')?_c('img',{attrs:{"src":require('@/assets/img/icon/km.svg')}}):_vm._e(),(item[1].split(' ')[0] == 'RB')?_c('img',{attrs:{"src":require('@/assets/img/icon/rb.svg')}}):_vm._e(),_vm._v(" "+_vm._s(item[1])+" "),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Dalam Ribuan")])]),_c('a-icon',{attrs:{"type":"info-circle"}})],2)],1)]):_vm._e()])}),_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(null)+" ")])],2)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }