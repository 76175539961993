<template>
  <div>
    <!-- Start Header -->
    <div class="ContentHeader">
      <div class="HeaderAll">
        <!-- Slot Breadcrumb -->
        <slot name="titlebreadcrumb"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <!-- Slot Benefit Header-->
      <slot name="benefitheader"></slot>
    </div>
    <!-- End Header -->

    <!-- slot menu -->
    <slot name="menuall"></slot>

    <div
      class="allcard"
      style="
        border-top-left-radius: 0px;
        height: -webkit-fill-available;
        position: absolute;
        width: 100%;
      "
    >
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <!-- start content here -->
        <div class="claimdetail">
          <a-skeleton :loading="loading">
            <a-row :gutter="[24, 24]">
              <div ref="infoBoxfirst" style="float: left; width: 100%">
                <a-col :lg="{ span: 12 }">
                  <div
                    class="claimcard"
                    :style="`min-height:${infoBoxfirst}px`"
                  >
                    <h2>Ketentuan Dasar Klaim:</h2>
                    <a-row>
                      <a-col :lg="12">
                        <div
                          class="sec-data-pasien"
                          v-for="(item, i) in ketentuan_claim.filter(
                            (key, i) => i < 2
                          )"
                          :key="i"
                        >
                          <div>
                            <label>{{ item.ketentuan_umum }}</label>
                            <p>{{ item.standar_andika }}</p>
                          </div>
                        </div>
                      </a-col>
                      <a-col :lg="12">
                        <div
                          class="sec-data-pasien"
                          v-for="(item, i) in ketentuan_claim.filter(
                            (key, i) => i >= 2
                          )"
                          :key="i"
                        >
                          <div>
                            <label>{{ item.ketentuan_umum }}</label>
                            <p>{{ item.standar_andika }}</p>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
                <a-col :lg="{ span: 12 }"
                  ><div
                    class="claimcard"
                    :style="`min-height:${infoBoxfirst}px`"
                  >
                    <h2>Ketentuan Dasar Kepesertaan:</h2>
                    <a-row :gutter="[15, 15]">
                      <a-col :lg="12">
                        <div
                          class="sec-data-pasien"
                          v-for="(item, i) in ketentuan_dasar_peserta.filter(
                            (key, i) => i <= 1
                          )"
                          :key="i"
                        >
                          <div>
                            <label>{{ item.ketentuan_umum }}</label>
                            <p>{{ item.standar_andika }}</p>
                          </div>
                        </div>
                      </a-col>
                      <a-col :lg="12">
                        <div
                          class="sec-data-pasien"
                          v-for="(item, i) in ketentuan_dasar_peserta.filter(
                            (key, i) => i >= 2
                          )"
                          :key="i"
                        >
                          <div>
                            <label>{{ item.ketentuan_umum }}</label>
                            <p>{{ item.standar_andika }}</p>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </div></a-col
                >
              </div>
            </a-row>
          </a-skeleton>
        </div>
        <!-- end content here -->
      </simplebar>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  components: {
    simplebar,
  },
  data() {
    return {
      infoBoxfirst: 0,
      ketentuan_claim: [],
      ketentuan_dasar_peserta: [],
      loading: false,
      breadcrumb: [
        {
          name: "Daftar Group",
          nameroute: "ListDaftarGroup",
          param: {},
        },
        {
          name: "Ketentuan Umum",
          nameroute: "",
          param: {},
        },
      ],
    };
  },
  mounted() {
    this.matcheight();
    this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("GetKetentuanUmum", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.loading = false;

          this.ketentuan_claim = response.data.data.ketentuan_claim;
          this.ketentuan_dasar_peserta =
            response.data.data.ketentuan_dasar_kepesertaan;
        });
    },
    matcheight() {
      this.infoBoxfirst = this.$refs.infoBoxfirst.clientHeight - 20;
    },
  },
};
</script>
