<template>
  <div class="detailclaim">
    <div class="ContentHeader">
      <div class="HeaderAll">
        <slot
          name="titlebreadcrumbDetail"
          :messageProps="data.produk_detail"
          :produk="data.produk"
          :noclaim="data.no_claim"
        ></slot>

        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="ContentHeaderSearch">
        <div class="statusclaim">
          <a-button
            type="mv"
            @click="history"
            v-if="data.status_claim == 'Menunggu Verifikasi'"
            ><img :src="require('@/assets/img/icon/mv.svg')" />
            {{ data.status_claim }}</a-button
          >
          <a-button
            type="sdoa"
            @click="history"
            v-if="data.status_claim == 'Sedang di-review oleh Asuransi'"
            ><img :src="require('@/assets/img/icon/sdoa.svg')" />
            {{ data.status_claim }}</a-button
          >

          <a-button
            type="td"
            @click="history"
            v-if="data.status_claim == 'Tidak Dicover'"
            ><img :src="require('@/assets/img/icon/td.svg')" />
            {{ data.status_claim }}</a-button
          >

          <a-button
            type="ddp"
            @click="history"
            v-if="data.status_claim == 'Disetujui Dibayarkan Penuh'"
            ><img :src="require('@/assets/img/icon/ddp.svg')" />
            {{ data.status_claim }}</a-button
          >

          <a-button
            type="mlk"
            @click="history"
            v-if="data.status_claim == 'Mohon Lengkapi Kekurangan Dokumen'"
            ><img :src="require('@/assets/img/icon/mlk.svg')" />
            {{ data.status_claim }}</a-button
          >

          <a-button
            type="dds"
            @click="history"
            v-if="data.status_claim == 'Disetujui Dibayarkan Sebagian'"
            ><img :src="require('@/assets/img/icon/dds.svg')" />
            {{ data.status_claim }}</a-button
          >

          <a-button
            type="pb"
            @click="history"
            v-if="data.status_claim == 'Proses Banding ke Asuransi'"
            ><img :src="require('@/assets/img/icon/pb.svg')" />
            {{ data.status_claim }}</a-button
          >
          <a-button
            type="pdg"
            @click="history"
            v-if="data.status_claim == 'Pending'"
            ><img :src="require('@/assets/img/icon/pdg.svg')" />
            {{ data.status_claim }}</a-button
          >
        </div>
      </div>
    </div>

    <!-- {{ loading }} -->
    <div
      v-if="loading"
      style="
        display: flex;
        justify-content: center;

        height: calc(100vh - 282px);
        border-radius: 10px;
      "
    >
      <LottieAnimation
        ref="anim"
        :animation-data="require('@/assets/img/lottie/loading.json')"
        :loop="true"
        :autoPlay="true"
        style="width: 50px"
      />
    </div>

    <div
      class="allcard"
      style="height: -webkit-fill-available; position: absolute; width: 100%"
      :style="loading && 'display:none'"
    >
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <div class="claimdetail">
          <a-row :gutter="[24, 24]" type="flex">
            <a-col
              :lg="{ span: 24 }"
              v-if="
                data.status_claim == 'Pending' ||
                data.status_claim == 'Mohon Lengkapi Kekurangan Dokumen'
              "
            >
              <div class="claimcard alertcard" style="margin-bottom: 0px">
                <div class="secs-tgl-maks">
                  <span
                    ><a-icon type="exclamation-circle" theme="filled" /> Tanggal
                    Maksimum Submit Kekurangan dokumen</span
                  >
                  <p><a-icon type="calendar" /> {{ data.tanggal_maksimum }}</p>
                </div>
              </div>
            </a-col>
            <div style="float: left; width: 100%" ref="infoBoxfirst">
              <a-col :lg="{ span: 12 }">
                <div
                  class="claimcard"
                  :style="`min-height:${infoBoxfirst}px; margin-bottom: 0px`"
                >
                  <h1>Data Pasien</h1>

                  <a-row>
                    <a-col :lg="8">
                      <div class="sec-data-pasien">
                        <div>
                          <label>Nama Pasien</label>
                          <p>
                            {{ `${data.nama_peserta}` }}
                          </p>
                        </div>

                        <div>
                          <label>Nama Karyawan</label>
                          <p>{{ data.nama_karyawan }}</p>
                        </div>
                      </div>
                    </a-col>
                    <a-col :lg="8"
                      ><div class="sec-data-pasien">
                        <div>
                          <label>Tanggal Lahir</label>
                          <p>
                            <a-icon type="calendar" />
                            {{ data.tanggal_lahir }}
                          </p>
                        </div>
                      </div></a-col
                    >

                    <a-col :lg="8">
                      <div class="sec-data-pasien">
                        <div>
                          <label>Unique ID</label>
                          <p>{{ data.unique_id }}</p>
                        </div>

                        <div>
                          <label>No. Peserta</label>
                          <p>{{ data.no_peserta }}</p>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </a-col>

              <a-col :lg="{ span: 12 }">
                <div
                  class="claimcard"
                  :style="`min-height:${infoBoxfirst}px; margin-bottom: 0px`"
                >
                  <a-row :gutter="[15]">
                    <a-col :lg="8">
                      <div class="sec-data-pasien">
                        <div>
                          <label>Nama Provider</label>
                          <p>{{ data.provider }}</p>
                        </div>

                        <div>
                          <label>Tanggal Perawatan</label>
                          <p>
                            <a-icon type="calendar" />
                            {{ data.tanggal_masuk }} -
                            {{ data.tanggal_keluar }}
                          </p>
                        </div>
                      </div>
                    </a-col>
                    <a-col :lg="8"
                      ><div class="sec-data-pasien">
                        <div>
                          <label>Remarks</label>
                          <p>{{ data.remarks }}</p>
                        </div>
                      </div></a-col
                    >

                    <a-col :lg="8">
                      <div class="sec-data-pasien">
                        <div>
                          <label>Kadaluarsa Klaim</label>
                          <p>{{ data.kadaluarsa_klaim }}</p>
                        </div>

                        <div>
                          <label>Coordination of Benefit</label>
                          <p>{{ data.cob }}</p>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
            </div>

            <div style="float: left; width: 100%" ref="infoBoxsecond">
              <a-col :lg="{ span: 12 }">
                <div
                  class="claimcard"
                  :style="`min-height:${infoBoxsecond}px; margin-bottom: 0px`"
                  ref="infoincomplete"
                >
                  <a-row :gutter="[10, 10]">
                    <a-col :lg="12">
                      <div class="claimincomplete">
                        <h3>Incomplete</h3>
                        <div
                          class="boxincomplete"
                          v-if="kelengkapan.incomplete.length > 0"
                          :style="`min-height:${infoincomplete}px`"
                        >
                          <div
                            v-for="(item, i) in kelengkapan.incomplete"
                            :key="i"
                          >
                            {{ item }}
                          </div>
                        </div>

                        <div
                          class="boxincomplete"
                          v-else
                          :style="`text-align: center; font-weight: 300; min-height:${infoincomplete}px`"
                        >
                          <a-empty :image="simpleImage">
                            <div slot="description">
                              Tidak ada kekurangan Dokumen
                            </div>
                          </a-empty>
                        </div>
                      </div>
                    </a-col>
                    <a-col :lg="12">
                      <div class="claimincomplete complete">
                        <h3>Complete</h3>
                        <div
                          class="boxincomplete"
                          v-if="Object.keys(kelengkapan.complete).length > 0"
                          :style="`min-height:${infoincomplete}px`"
                        >
                          <div
                            v-for="(item, i) in kelengkapan.complete"
                            :key="i"
                          >
                            {{ item }}
                          </div>
                        </div>

                        <div
                          class="boxincomplete"
                          v-else
                          :style="`text-align: center; font-weight: 300; min-height:${infoincomplete}px`"
                        >
                          <a-empty :image="simpleImage">
                            <div slot="description">
                              Tidak ada kekurangan Dokumen
                            </div>
                          </a-empty>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </a-col>

              <a-col :lg="{ span: 6 }">
                <div
                  class="claimcard"
                  :style="`min-height:${infoBoxsecond}px; margin-bottom: 0px`"
                >
                  <div class="sec-data-pasien">
                    <div v-if="data.tanggal_approval != null">
                      <label>Tanggal Approval</label>
                      <p>
                        <a-icon type="calendar" />
                        {{ data.tanggal_approval }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h4>Nominal Pengajuan</h4>
                    <div class="sec-nominal">
                      <span>Jumlah Diajukan</span>
                      <span>{{
                        `${data.mata_uang} 
                          ${numberWithCommas(data.jumlah_diajukan)}`
                      }}</span>
                    </div>
                    <div class="sec-nominal" v-if="data.co_share != '0'">
                      <span>{{ data.co_share_label }}</span>
                      <span v-if="data.co_share_value != null">{{
                        `${data.mata_uang_co_share} ${numberWithCommas(
                          data.co_share_value
                        )}.00`
                      }}</span>
                    </div>
                  </div>
                  <div v-if="data.final_paid != null">
                    <h4>Pembayaran Final</h4>
                    <div class="sec-nominal" v-if="data.final_ekses != null">
                      <span>Final Ekses</span>
                      <span>{{
                        `${data.mata_uang_final_ekses ?? ""} ${numberWithCommas(
                          data.final_ekses
                        )}`
                      }}</span>
                    </div>
                    <!-- <div class="sec-nominal" v-if="data.ex_gratia != null">
                      <span>Ex-Gratia</span>
                      <span>{{ numberWithCommas(ex_gratia) }}</span>
                    </div> -->

                    <div
                      class="sec-nominal"
                      style="font-weight: bold"
                      v-if="data.final_paid != null"
                    >
                      <span>Final Paid</span>
                      <span style="color: rgb(255, 87, 34)">{{
                        `${data.mata_uang_final_paid} ${numberWithCommas(
                          data.final_paid
                        )}`
                      }}</span>
                    </div>

                    <div
                      class="sec-nominal"
                      style="font-weight: bold"
                      v-if="data.file_eob != null"
                    >
                      <span>
                        <a
                          :href="`${
                            axios.replace('api', '') + '' + data.file_eob
                          }`"
                          ><img
                            :src="require('@/assets/img/icon/fileeob.svg')" />
                          Download EOB <a-icon type="right"
                        /></a>
                      </span>
                    </div>
                  </div>
                </div>
              </a-col>

              <a-col :lg="{ span: 6 }">
                <!-- <div class="claimcard" :style="`min-height:${infoBoxsecond}px`"> -->
                <div
                  class="claimcard"
                  :style="`min-height:${infoBoxsecond}px; margin-bottom: 0px`"
                >
                  <div class="sec-data-pasien">
                    <div>
                      <label>Alasan Ekses</label>
                      <ol v-if="data.alasan != null">
                        <li
                          v-for="(item, i) in JSON.parse(data.alasan)"
                          :key="i"
                        >
                          {{ item }}
                        </li>
                      </ol>
                      <div v-else>
                        <a-empty :image="simpleImage">
                          <div slot="description">Data tidak tersedia</div>
                        </a-empty>
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </div>

            <div style="float: left; width: 100%" ref="infoBoxthird">
              <a-col :lg="{ span: 12 }">
                <div
                  class="claimcard"
                  :style="`min-height:${infoBoxthird}px; margin-bottom: 0px`"
                >
                  <h1>Data Perusahaan</h1>
                  <div class="sec-data-pasien">
                    <div>
                      <label>Nama Group</label>
                      <p>{{ data.group }}</p>
                    </div>

                    <div>
                      <label>Nama Perusahaan</label>
                      <p>{{ data.nama_perusahaan }}</p>
                    </div>

                    <div>
                      <label>Branch</label>
                      <p>{{ data.cabang }}</p>
                    </div>
                    <div>
                      <label>Sub Branch</label>
                      <p>
                        <span v-if="data.sub_cabang_satu != null">{{
                          data.sub_cabang_satu
                        }}</span>
                        <span v-else>-</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a-col>

              <a-col :lg="{ span: 12 }">
                <div
                  class="claimcard"
                  :style="`min-height:${infoBoxthird}px; margin-bottom: 0px`"
                >
                  <h1>Informasi</h1>
                  <div class="sec-data-pasien">
                    <div>
                      <label>Asuransi</label>
                      <p>{{ data.asuransi }}</p>
                    </div>

                    <div>
                      <label>Plan</label>
                      <div>
                        <a-popover
                          trigger="click"
                          v-for="(item, i) in plan"
                          :key="i"
                          type="plan"
                          @visibleChange="
                            planshow(data.id_job_order, item.plan, item.value)
                          "
                          placement="topRight"
                        >
                          <template slot="content">
                            <simplebar
                              class="simplebarcustom"
                              data-simplebar-auto-hide="false"
                            >
                              <div class="poopercustom">
                                <table v-if="databenefit.length > 0">
                                  <thead>
                                    <tr>
                                      <td>Manfaat</td>
                                      <td>Benefit</td>
                                      <td>Plan {{ databenefit[0].val }}</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in databenefit.filter(
                                        (key) => key.rowid > 1
                                      )"
                                      :key="i"
                                    >
                                      <td>{{ item.manfaat_plan }}</td>
                                      <td>{{ item.maksimal_limit }}</td>
                                      <td>{{ item.val }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </simplebar>
                          </template>
                          <a-button style="margin-right: 20px"
                            ><img
                              :src="
                                require(`@/assets/img/icon/${item.icon}.svg`)
                              "
                            />{{ `${item.plan} - ${item.value}` }}</a-button
                          >
                        </a-popover>
                        <!-- <a-dropdown
                            :trigger="['click']"
                            placement="topRight"
                            v-for="(item, i) in plan"
                            :key="i"
                          >
                            <a-button
                              type="plan"
                              @click="
                                planshow(
                                  data.id_job_order,
                                  item.plan,
                                  item.value
                                )
                              "
                              ><img
                                :src="
                                  require(`@/assets/img/icon/${item.icon}.svg`)
                                "
                              />{{ `${item.plan} - ${item.value}` }}</a-button
                            >
                            <div slot="overlay" class="overlayplan">
                              <div
                                v-if="databenefit.length > 0"
                                style="
                                  max-height: 600px;
                                  overflow-y: auto;
                                  padding: 20px;
                                "
                              >
                                <table style="">
                                  <thead>
                                    <tr>
                                      <td>Manfaat</td>
                                      <td>Benefit</td>
                                      <td>Plan {{ databenefit[0].val }}</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, i) in databenefit.filter(
                                        (key) => key.rowid > 1
                                      )"
                                      :key="i"
                                    >
                                      <td>{{ item.manfaat_plan }}</td>
                                      <td>{{ item.maksimal_limit }}</td>
                                      <td>{{ item.val }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <a-empty v-else />
                            </div>
                          </a-dropdown> -->
                      </div>
                    </div>

                    <div v-if="data.co_share != null">
                      <label>Co Share</label>
                      <p>
                        {{ data.co_share }}
                        {{ data.co_share == 0 ? "" : "%" }}
                      </p>
                    </div>
                  </div>
                </div>
              </a-col>
            </div>
          </a-row>
        </div>
      </simplebar>
    </div>

    <HistoryClaim ref="History" />
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import $axios from "@/utils/axios.js";
import { Empty } from "ant-design-vue";
import HistoryClaim from "@/components/Modal/historyClaim.vue";
import LottieAnimation from "lottie-web-vue";

const plan = [
  {
    icon: "x",
    plan: "RI-100",
  },
  {
    icon: "x",
    plan: "RI-900",
  },
  {
    icon: "x",
    plan: "RI-800",
  },
];
export default {
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  components: {
    HistoryClaim,
    simplebar,
    LottieAnimation,
  },
  data() {
    return {
      infoBoxthird: 0,
      infoBoxfirst: 0,
      infoBoxsecond: 0,
      infoincomplete: 0,
      plan: [],
      data: {},
      kelengkapan: { complete: [], incomplete: [] },
      loading: false,
      loadingbenefit: false,
      databenefit: [],
      axios: $axios.defaults.baseURL,
      settings: {
        suppressScrollY: true,
        suppressScrollX: true,
        wheelPropagation: true,
      },
      breadcrumb: [
        {
          name: "Rekap Klaim",
          nameroute: "ListKlaim",
          param: {},
        },
        {
          name: "Detail Klaim",
          nameroute: "",
          param: {},
        },
      ],
    };
  },

  watch: {
    $route(to, from) {
      this.fetch();
    },
  },

  created() {
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
  },
  mounted() {
    document.body.classList.add("poopersbody");
    this.fetch();
  },
  methods: {
    handleResize() {
      console.log(this.$refs);
    },
    planshow(id, plan, value) {
      this.loadingbenefit = true;
      let val = value.replace(/\s/g, "");

      this.$store
        .dispatch("getBenefit", {
          id: id,
          plan: plan,
          value: value,
        })
        .then((response) => {
          this.loadingbenefit = false;
          this.databenefit = response.data.data;

          // console.log(this.databenefit);
        });
    },
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("getDetailClaim", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.loading = false;
          this.$nextTick(() => {
            this.matcheight();
          });
          (this.data = response.data.data.claim),
            (this.kelengkapan = response.data.data.kelengkapan),
            (this.plan = response.data.data.plan);
        });
    },
    history() {
      this.$refs.History.modal();
    },
    matcheight() {
      if (!this.loading) {
        this.infoBoxfirst = this.$refs.infoBoxfirst.clientHeight - 20;
        this.infoBoxsecond = this.$refs.infoBoxsecond.clientHeight - 20;
        this.infoBoxthird = this.$refs.infoBoxthird.clientHeight - 20;
        this.infoincomplete = this.$refs.infoincomplete.clientHeight - 20;

        console.log(this.infoincomplete);
      }
    },
  },
};
</script>

<style scoped>
.ant-modal-title {
  color: #0060d1 !important;
}

.ant-modal-header {
  margin: 0px -22px;
  border-radius: 15px;
}
</style>
