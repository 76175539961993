<template>
  <div>
    <!-- <div
      v-if="!loading && $route.name !== 'ListKlaim'"
      style="
        display: flex;
        justify-content: center;

        height: calc(100vh - 282px);
        border-radius: 10px;
      "
    >
      <LottieAnimation
        ref="anim"
        :animation-data="require('@/assets/img/lottie/loading.json')"
        :loop="true"
        :autoPlay="true"
        style="width: 50px"
      />
    </div> -->
    <router-view>
      <template slot="titlebreadcrumb">
        <slot name="breadcrumb"></slot>
      </template>

      <template #titlebreadcrumbDetail="{ messageProps, produk, noclaim }">
        <!-- {{ typeof produk === "string" ? produk.toLowerCase() : "" }} -->

        <div>
          <div>
            <h1>{{ $route.meta.title }} - {{ noclaim }}</h1>

            <div
              class="iconpeserta"
              style="
                padding: 9px 16px !important;
                cursor: pointer;
                margin-top: 3px;
              "
              @click="InfoProduk(messageProps)"
              v-if="produk !== undefined"
            >
              <img
                :src="require(`@/assets/img/icon/${produk.toLowerCase()}.svg`)"
                @load="loaded"
              />

              {{ messageProps }}
            </div>
          </div>
          <slot name="onlybreadcrumb"></slot>

          <Produk ref="Action" />
        </div>
      </template>
    </router-view>
  </div>
</template>

<script>
//import LottieAnimation from "lottie-web-vue";
import Produk from "@/components/Modal/plan.vue";
export default {
  // props: {
  //   messageProps: String,
  // },
  components: {
    Produk,
    //LottieAnimation,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  methods: {
    loaded() {
      this.loading = true;
    },
    InfoProduk(item) {
      this.$refs.Action.Show(item.split(" ")[0]);
    },
  },
};
</script>
