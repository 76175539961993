import Vue from "vue";
import axios from "axios";
// import {API_URL} from '@/config.js'
import $router from "@/router/index.js";
import $store from "@/store/index.js";
import notification from "ant-design-vue/lib/notification";

Vue.prototype.$http = axios;

// axios.defaults.timeout = 18000;

// const URL =
//   window.location.protocol == "https:"
//     ? "https://andikaassistant.id/be/api"
//     : "http://192.168.80.214:1984/api";

// const URL = "http://192.168.80.214:1984/api";

const URL = "https://andikaassistant.id/be/api";
// const URL = "http://192.168.20.12/be-hrportal/api";

const $axios = Vue.prototype.$http.create({
  baseURL: URL,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
  },
});

$axios.defaults.timeout = 300000;

$axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    } else {
      config.headers["Authorization"] = null;
      delete config.headers["Authorization"];
    }
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      notification.error({
        message: "Terjadi Kesalahan",
      });
    } else {
      if (error.response.status === 401) {
        // notification.error({
        //   message: error.response.data.message,
        // });
        $store.dispatch("Unautorized");

        //$router.push({name:'Login'})

        //location.reload();

        notification.error({
          message: error.response.data.message,
        });

        return Promise.reject(error);
      }

      if (error.response.status === 400) {
        if (
          error.response.data.message != "Ada data yang belum sesuai ketentuan"
        ) {
          notification.error({
            message: error.response.data.message,
          });
        }
        return Promise.reject(error);
      }

      if (error.response.status === 500) {
        notification.error({
          message: "Terjadi Kesalahan Pada Server",
        });
        return Promise.reject(error);
      }

      if (error.response.status === 422) {
        //const err = Object.values(error.response.data)

        const err = Object.values(error.response.data);

        err.map((key) => {
          notification.error({
            message: "Peringatan",
            description: key[0],
          });
        });

        return Promise.reject(error);
      }

      if (error.response.status === 404) {
        notification.error({
          message: error.response.data.message,
        });
        return Promise.reject(error);
      }

      if (error.response.status === 405) {
        notification.error({
          message: "Method Not Allowed",
        });
        return Promise.reject(error);
      }

      if (error.response.status === 302) {
        notification.error({
          message: error.response.data.message,
        });
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default $axios;
