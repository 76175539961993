<template>
  <div ref="initial">
    <div class="ContentHeader">
      <div class="HeaderAll">
        <slot name="titlebreadcrumb"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <div class="ContentHeaderSearch">
        <a-input-search
          placeholder="Input disini"
          style="width: 327px"
          @search="onSearch"
          allowClear
          :default-value="
            $route.query.search != undefined ? $route.query.search : ''
          "
        />

        <a-button type="advancedsearch" ref="buttonadvanced" @click="advanced"
          ><a-badge :dot="dotAdvancedSearch"
            >Advanced Search <a-icon type="filter"
          /></a-badge>
        </a-button>

        <div
          class="advancedsearch-dropdown"
          :style="advancedsearch ? 'display: block;' : 'display: none;'"
          @click="closeadvance"
        >
          <div class="_dropdownwraper" :style="{ top: top + 'px' }">
            <div class="button-right-top claim-right-top">
              <a-button type="link" @click="advanced">
                <span class="ant-modal-close-x"> <a-icon type="close" /></span
              ></a-button>
            </div>
            <a-form :form="form" @submit="Submit">
              <a-row :gutter="[15, 15]">
                <a-col :lg="6">
                  <a-form-item label="Nama Pasien">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'nama_pasien',
                        {
                          initialValue:
                            $route.query.nama_pasien != undefined
                              ? $route.query.nama_pasien
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>
                <a-col :lg="6">
                  <a-form-item label="No.Peserta">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'no_peserta',
                        {
                          initialValue:
                            $route.query.no_peserta != undefined
                              ? $route.query.no_peserta
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>
                <a-col :lg="6">
                  <a-form-item label="Nama Perusahaan">
                    <select2
                      style="width: 100% !important; display: grid"
                      v-decorator="[
                        'nama_perusahaan',
                        {
                          initialValue:
                            $route.query.nama_perusahaan != undefined
                              ? $route.query.nama_perusahaan
                              : null,
                        },
                      ]"
                      :options="nama_perusahaan"
                    />
                  </a-form-item>
                </a-col>
                <a-col :lg="6">
                  <a-form-item label="Nama Karyawan">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'nama_karyawan',
                        {
                          initialValue:
                            $route.query.nama_karyawan != undefined
                              ? $route.query.nama_karyawan
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="No Claim">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'no_claim',
                        {
                          initialValue:
                            $route.query.no_claim != undefined
                              ? $route.query.no_claim
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>
                <a-col :lg="6">
                  <a-form-item label="Status">
                    <a-select
                      autocomplete="off"
                      v-decorator="[
                        'status',
                        {
                          initialValue:
                            $route.query.status != undefined
                              ? $route.query.status
                              : null,
                        },
                      ]"
                      placeholder="Pilih status"
                    >
                      <a-select-option value="Menunggu Verifikasi">
                        <span style="color: rgb(0, 196, 196)"
                          >Menunggu Verifikasi</span
                        ></a-select-option
                      >
                      <a-select-option value="Hold"
                        ><span style="color: #333">Hold</span></a-select-option
                      >
                      <a-select-option value="Pending"
                        ><span style="color: #ee5843"
                          >Pending</span
                        ></a-select-option
                      >
                      <a-select-option value="Mohon Lengkapi Kekurangan Dokumen"
                        ><span style="color: #ff9900"
                          >Mohon Lengkapi Kekurangan Dokumen</span
                        ></a-select-option
                      >
                      <a-select-option value="Sedang di-review oleh Asuransi"
                        ><span style="color: #4c88ff"
                          >Sedang di-review oleh Asuransi</span
                        ></a-select-option
                      >
                      <a-select-option value="Proses Banding ke Asuransi"
                        ><span style="color: #6464ff"
                          >Proses Banding ke Asuransi</span
                        ></a-select-option
                      >
                      <a-select-option value="Tidak Dicover"
                        ><span style="color: #ea9999"
                          >Tidak Dicover</span
                        ></a-select-option
                      >
                      <a-select-option value="Disetujui Dibayarkan Sebagian"
                        ><span style="color: #20cc86"
                          >Disetujui Dibayarkan Sebagian</span
                        ></a-select-option
                      >

                      <a-select-option value="Disetujui Dibayarkan Penuh"
                        ><span style="color: #00b407"
                          >Disetujui Dibayarkan Penuh</span
                        ></a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="Produk Plan">
                    <a-select
                      autocomplete="off"
                      v-decorator="[
                        'produk',
                        {
                          initialValue:
                            $route.query.produk != undefined
                              ? $route.query.produk
                              : null,
                        },
                      ]"
                      placeholder="Pilih status"
                    >
                      <a-select-option value="RI">RI</a-select-option>
                      <a-select-option value="RJ">RJ</a-select-option>
                      <a-select-option value="KM">KM</a-select-option>
                      <a-select-option value="RG">RG</a-select-option>
                      <a-select-option value="RB">RB</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="Tanggal Masuk">
                    <a-range-picker
                      autocomplete="off"
                      v-decorator="[
                        'tanggal_masuk',
                        {
                          initialValue:
                            $route.query.tanggal_masuk != undefined
                              ? $route.query.tanggal_masuk
                              : null,
                        },
                      ]"
                      style="width: 100%"
                      format="DD MMM YYYY"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="Tanggal Keluar">
                    <a-range-picker
                      autocomplete="off"
                      v-decorator="[
                        'tanggal_keluar',
                        {
                          initialValue:
                            $route.query.tanggal_keluar != undefined
                              ? $route.query.tanggal_keluar
                              : null,
                        },
                      ]"
                      style="width: 100%"
                      format="DD MMM YYYY"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="Jumlah yang Diajukan">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'jumlah_diajukan',
                        {
                          initialValue:
                            $route.query.jumlah_diajukan != undefined
                              ? $route.query.jumlah_diajukan
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="Jumlah yang Dibayarkan">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'jumlah_dibayarkan',
                        {
                          initialValue:
                            $route.query.jumlah_dibayarkan != undefined
                              ? $route.query.jumlah_dibayarkan
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="Ekses">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'ekses',
                        {
                          initialValue:
                            $route.query.ekses != undefined
                              ? $route.query.ekses
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="6">
                  <a-form-item label="Keterangan Ekses">
                    <a-input
                      autocomplete="off"
                      v-decorator="[
                        'alasan',
                        {
                          initialValue:
                            $route.query.alasan != undefined
                              ? $route.query.alasan
                              : null,
                        },
                      ]"
                      placeholder="Input disini"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="24">
                  <div class="button-right">
                    <a-button type="default" @click="Reset">Reset</a-button>
                    <a-button type="primary" html-type="submit">Cari</a-button>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>
      </div>
    </div>

    <a-table
      :rowKey="(record) => record.id"
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: 1200, y: heighttable }"
      @change="handleTableChange"
      :customRow="customRow"
    >
      <div slot="filterDropdownMasuk" slot-scope="{ column }">
        <div class="wrapbuttonsort">
          <a-button
            type="link"
            @click="
              handlesTbl('tanggal_masuk', [undefined, 'ascend', 'descend'])
            "
          >
            <div class="customsortdown">
              <div
                :style="
                  column.choseSort == 'tanggal_masuk'
                    ? 'color:#0060D1'
                    : 'color:#77849D'
                "
              >
                Tanggal Masuk
              </div>
            </div>
          </a-button>
        </div>
        <div>
          <a-button
            type="link"
            @click="
              handlesTbl('tanggal_keluar', [undefined, 'ascend', 'descend'])
            "
          >
            <div class="customsortdown">
              <div
                :style="
                  column.choseSort == 'tanggal_keluar'
                    ? 'color:#0060D1'
                    : 'color:#77849D'
                "
              >
                Tanggal Keluar
              </div>
            </div>
          </a-button>
        </div>
      </div>

      <div
        slot="filterIconMasuk"
        slot-scope="filtered, column"
        class="customSorter"
      >
        <a-icon
          type="caret-up"
          :style="{
            color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
          }"
        />

        <a-icon
          type="caret-down"
          :style="{
            color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
          }"
        />
      </div>
      <div slot="filterDropdown" slot-scope="{ column }">
        <div class="wrapbuttonsort">
          <a-button
            type="link"
            @click="
              handlesTbl('nama_peserta', [undefined, 'ascend', 'descend'])
            "
          >
            <div class="customsortdown">
              <div
                :style="
                  column.choseSort == 'nama_peserta'
                    ? 'color:#0060D1'
                    : 'color:#77849D'
                "
              >
                Nama Pasien
              </div>
            </div>
          </a-button>
        </div>
        <div>
          <a-button
            type="link"
            @click="handlesTbl('no_peserta', [undefined, 'ascend', 'descend'])"
          >
            <div class="customsortdown">
              <div
                :style="
                  column.choseSort == 'no_peserta'
                    ? 'color:#0060D1'
                    : 'color:#77849D'
                "
              >
                No Peserta
              </div>
            </div>
          </a-button>
        </div>
      </div>

      <div slot="filterIcon" slot-scope="filtered, column" class="customSorter">
        <a-icon
          type="caret-up"
          :style="{
            color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
          }"
        />

        <a-icon
          type="caret-down"
          :style="{
            color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
          }"
        />
      </div>
      <template slot="setatus">
        Status
        <a-button type="link" @click="ModalStatus"
          ><a-icon type="info-circle"
        /></a-button>
      </template>
      <template slot="titlenamapasien"> Nama Pasien<br />No. Peserta </template>
      <template slot="approval_date"> Tanggal<br />Approval </template>

      <template slot="titlekaryawan"> Nama<br />Karyawan </template>
      <template slot="jumlah_ajukan"> Jumlah<br />Diajukan </template>

      <template slot="tgl_masuk"> Tanggal<br />Masuk & Keluar </template>
      <template slot="ket_ekses"> Keterangan<br />Ekses </template>
      <!-- ------------------ -->
      <template slot="no_claim" slot-scope="text, record">
        <router-link :to="{ name: 'DetailKlaim', params: { id: record.id } }"
          >{{ text }}
          <a-icon
            type="right"
            style="
              font-size: 11px !important;
              font-weight: 500;
              padding-left: 10px;
            "
        /></router-link>
      </template>

      <!-- ------------------ -->
      <template slot="alasan" slot-scope="text">
        <div v-if="text != null">
          <a-tooltip placement="leftTop">
            <template slot="title">
              <ol style="padding-left: 10; margin: 0">
                <li v-for="(item, i) in JSON.parse(text)" :key="i">
                  {{ item }}
                </li>
              </ol>
            </template>
            <ul class="listekses">
              <li>
                <span>1 </span>
                <span
                  style="
                    white-space: nowrap;
                    display: inline-block;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >{{ JSON.parse(text)[0] }}</span
                >
              </li>
              <li v-if="JSON.parse(text).length > 2">
                <span>2 </span> <span>...</span>
              </li>
              <li v-if="JSON.parse(text).length == 2">
                <span>2 </span>
                <span
                  style="
                    white-space: nowrap;
                    display: inline-block;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >{{ JSON.parse(text)[1] }}</span
                >
              </li>
              <li
                v-if="
                  JSON.parse(text).length > 1 && JSON.parse(text).length != 2
                "
              >
                <span>{{ JSON.parse(text).length }} </span>
                <span
                  style="
                    white-space: nowrap;
                    display: inline-block;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >{{ JSON.parse(text)[JSON.parse(text).length - 1] }}</span
                >
              </li>
            </ul>
          </a-tooltip>
        </div>

        <div v-else>-</div>
      </template>
      <!-- ------------------ -->
      <template slot="nama_pasien" slot-scope="text, record">
        <div :title="text">
          <router-link
            :to="{
              name: 'DetailPeserta',
              params: {
                id: record.id_job_order,
                idpeserta: record.id_peserta,
              },
            }"
          >
            <span
              style="
                white-space: nowrap;
                display: inline-block;
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{ text }}</span
            >
            <a-icon
              type="right"
              style="
                font-size: 11px !important;
                font-weight: bolder !important;
                padding-left: 10px;
                vertical-align: text-top;
              "
            />
          </router-link>
        </div>
        <div>{{ record.no_peserta }}</div>
      </template>
      <!-- ------------------ -->

      <!--  -->

      <template slot="nama_karyawan" slot-scope="text, record">
        <router-link
          :to="{
            name: 'DetailPeserta',
            params: {
              id: record.id_job_order,
              idpeserta: record.id_karyawan_self,
            },
          }"
        >
          <span
            style="
              white-space: nowrap;
              display: inline-block;
              width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ text }}</span
          >
          <a-icon
            type="right"
            style="
              font-size: 11px !important;
              font-weight: 500;
              padding-left: 10px;
            "
          />
        </router-link>
      </template>

      <!--  -->
      <template slot="tgl_masuk_keluar" slot-scope="text, record">
        <div class="iconcalendar" style="padding-bottom: 10px">
          <img :src="require('@/assets/img/icon/periode_awal.svg')" />
          {{ record.tanggal_masuk }}
        </div>
        <div class="iconcalendar">
          <img :src="require('@/assets/img/icon/periode_akhir.svg')" />
          {{ record.tanggal_keluar }}
        </div>
      </template>
      <!-- ------------------ -->
      <template slot="jumlah_diajukan" slot-scope="text, record">
        <div v-if="text == null">-</div>
        <div v-else>
          <div style="font-weight: bold; color: #060d17">
            {{ record.mata_uang }}
          </div>
          <div style="font-weight: bold; color: #060d17">
            {{ numberWithCommas(text) }}
          </div>
        </div>
      </template>

      <!-- ------------------ -->
      <span slot="jumlah_bayar">Jumlah<br />Dibayarkan</span>
      <template slot="jumlah_dibayarkan" slot-scope="text, record">
        <div v-if="text == null">-</div>
        <div v-else>
          <div style="font-weight: bold; color: #060d17">
            {{ record.mata_uang_dibayarkan }}
          </div>
          <div style="font-weight: bold; color: #060d17">
            <!-- global variable -->
            {{ numberWithCommas(text) }}
          </div>
        </div>
      </template>

      <!-- ------------------ -->
      <template slot="ekses" slot-scope="text, record">
        <div v-if="text == null">-</div>
        <div v-else>
          <div style="font-weight: bold; color: #060d17">
            {{ record.mata_uang_ekses }}
          </div>
          <div style="font-weight: bold; color: #060d17">
            {{ numberWithCommas(text) }}
          </div>
        </div>
      </template>

      <!-- ------------------ -->

      <template slot="produk_plan" slot-scope="text">
        <div class="iconpeserta" v-if="text != 'NON PLAN/PRODUCT'">
          <img :src="require(`@/assets/img/icon/${text.toLowerCase()}.svg`)" />
          {{ text }}
        </div>
        <div v-else>-</div>
      </template>

      <template slot="status" slot-scope="text">
        <div class="wrapstatusclaim">
          <a-tooltip placement="top" v-if="text == '-'" title="HOLD">
            <!-- <div class="va" style="">
              <img :src="require('@/assets/img/icon/hold.svg')" /> HOLD
            </div> -->
            {{ text }}
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Verifikasi Asuransi'"
            title="Verifikasi Asuransi"
          >
            <div class="va">
              <img :src="require('@/assets/img/icon/va.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Menunggu Verifikasi'"
            title="Menunggu Verifikasi"
          >
            <div class="mv">
              <img :src="require('@/assets/img/icon/mv.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Proses Banding ke Asuransi'"
            title="Proses Banding ke Asuransi"
          >
            <div class="pb">
              <img :src="require('@/assets/img/icon/pb.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Mohon Lengkapi Kekurangan Dokumen'"
            title="Mohon Lengkapi Kekurangan Dokumen"
          >
            <div class="mlk">
              <img :src="require('@/assets/img/icon/mlk.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip placement="top" v-if="text == 'Pending'" title="Pending">
            <div class="pdg">
              <img :src="require('@/assets/img/icon/pdg.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Sedang di-review oleh Asuransi'"
            title="Sedang di-review oleh Asuransi"
          >
            <div class="sdoa">
              <img :src="require('@/assets/img/icon/sdoa.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Disetujui Dibayarkan Sebagian'"
            title="Disetujui Dibayarkan Sebagian"
          >
            <div class="dds">
              <img :src="require('@/assets/img/icon/dds.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Disetujui Dibayarkan Penuh'"
            title="Disetujui Dibayarkan Penuh"
          >
            <div class="ddp">
              <img :src="require('@/assets/img/icon/ddp.svg')" /> {{ text }}
            </div>
          </a-tooltip>

          <a-tooltip
            placement="top"
            v-if="text == 'Tidak Dicover'"
            title="Tidak Dicover"
          >
            <div class="td">
              <img :src="require('@/assets/img/icon/td.svg')" /> {{ text }}
            </div>
          </a-tooltip>
        </div>
      </template>

      <template slot="tgl_approval" slot-scope="text">
        <div
          class="iconcalendar"
          style="padding-bottom: 10px"
          v-if="text != null"
        >
          <img
            :src="require('@/assets/img/icon/dateapproval.svg')"
            style="margin-right: -8px; vertical-align: middle"
          />
          {{ text }}
        </div>
        <div v-else>-</div>
      </template>

      <template slot="footer">
        {{ null }}
      </template>
    </a-table>
    <div class="paginationcustom" style="margin-top: -10px !important">
      <a-pagination
        showSizeChanger
        @showSizeChange="onShowSizeChange"
        :pageSizeOptions="pageSizeOption"
        :pageSize.sync="pageSize"
        :total="total"
        v-model="page"
        :showTotal="
          (total, range) =>
            ` ${range[0]}-${range[1]}  of ${
              pagination.total == undefined ? '0' : pagination.total
            } `
        "
        @change="handlePageChange"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '1000000'">
            {{ props.value }} / page
          </span>
          <span v-if="props.value == '1000000'">All</span>
        </template>
      </a-pagination>
    </div>

    <ModalStatus ref="action" />
  </div>
</template>

<script>
import ModalStatus from "@/components/Modal/StatusClaim.vue";
const columns = [
  // {
  //   dataIndex: "id",
  //   width: 45,
  //   slots: { title: "checkTitle" },
  //   align: "center",
  //   scopedSlots: { customRender: "SelectAll" },
  // },

  {
    dataIndex: "nama_peserta",
    scopedSlots: {
      customRender: "nama_pasien",
      title: "titlenamapasien",
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
    },
    customSort: "",
    choseSort: "",
    width: 180,
    fixed: "left",
  },
  {
    dataIndex: "nama_karyawan",
    scopedSlots: {
      customRender: "nama_karyawan",
      title: "titlekaryawan",
    },
    sorter: true,
    width: 180,
    fixed: "left",
  },
  {
    title: "No Claim",
    dataIndex: "no_claim",
    scopedSlots: { customRender: "no_claim" },
    sorter: true,
    width: 230,
  },

  {
    dataIndex: "status_claim",
    scopedSlots: { customRender: "status", title: "setatus" },
    sorter: true,
    width: 200,
    align: "center",
  },

  {
    title: "Product Plan",
    dataIndex: "produk",
    scopedSlots: { customRender: "produk_plan" },
    sorter: true,
    width: 150,
  },
  {
    dataIndex: "tgl_masuk",
    scopedSlots: {
      customRender: "tgl_masuk_keluar",
      title: "tgl_masuk",
      filterDropdown: "filterDropdownMasuk",
      filterIcon: "filterIconMasuk",
    },
    customSort: "",
    choseSort: "",
    width: 180,
  },

  {
    dataIndex: "jumlah_diajukan",
    scopedSlots: { customRender: "jumlah_diajukan", title: "jumlah_ajukan" },
    sorter: true,
    width: 180,
  },

  {
    dataIndex: "jumlah_dibayarkan",
    scopedSlots: {
      title: "jumlah_bayar",
      customRender: "jumlah_dibayarkan",
    },
    sorter: true,
    width: 180,

    // slots: { title: "jumlah_dibayarkan" },
  },

  {
    title: "Ekses",
    dataIndex: "ekses",
    scopedSlots: { customRender: "ekses" },
    sorter: true,
    width: 150,
  },

  {
    dataIndex: "alasan",
    scopedSlots: { customRender: "alasan", title: "ket_ekses" },
    sorter: true,
    width: 250,
  },
  {
    dataIndex: "tanggal_approval",
    scopedSlots: { customRender: "tgl_approval", title: "approval_date" },
    sorter: true,
    width: 200,
  },
  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   scopedSlots: { customRender: "action" },
  //   width: 250,
  //   align: "center",
  // },
];
// import Table from "@/components/Table/table.vue";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormAdvancedSearch" });
  },
  components: {
    // Table,
    ModalStatus,
  },
  data() {
    return {
      data: [],
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 0,
      page: 1,
      current: 1,
      pageSize: 15,
      showTotal: "",
      columns: columns,
      model: [],
      dotAdvancedSearch:
        Object.keys(this.$route.query).length >= 15 &&
        this.$route.query.search == undefined
          ? true
          : Object.keys(this.$route.query).length >= 15 &&
            this.$route.query.search != ""
          ? true
          : false,
      advancedsearch: false,
      top: 0,
      loading: false,
      paramssearch:
        this.$route.query.page != undefined &&
        this.$route.query.pageSizes != undefined
          ? Object.keys(this.$route.query).reduce((obj, key) => {
              if (key !== "page" && key !== "pageSizes") {
                obj[key] = this.$route.query[key];
              }

              return obj;
            }, {})
          : {},
      search:
        this.$route.query.search != undefined ? this.$route.query.search : "",
      click: 0,
      labels: "",
      heighttable: 0,
      breadcrumb: [
        {
          name: "Rekap Klaim",
          nameroute: "",
          param: {},
        },
      ],
    };
  },

  computed: {
    nama_perusahaan() {
      return this.$store.state.Auth.data.perusahaan.map((key) => ({
        id: key.id_job_order_perusahaan,
        text: key.nama_perusahaan,
      }));
    },
  },

  created() {},
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    const search = this.search != "" ? { search: this.search } : {};
    const params = this.paramssearch != undefined ? this.paramssearch : {};

    this.fetch({
      page: this.page,
      pageSizes: this.pageSize,
      ...search,
      ...params,
    });
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: (event) => {
            this.$message.success("Double klik untuk kehalaman detail");
          },
          dblclick: (event) => {
            console.log(record);

            this.$router.push({
              name: "DetailKlaim",
              params: { id: record.id },
            });
          },
        },
      };
    },
    ModalStatus() {
      this.$refs.action.Show();
    },
    handleResize() {
      //console.log(screen.height);
      if (screen.height <= 768) {
        this.heighttable = screen.height - 420;
      } else {
        this.heighttable = screen.height - 530;
      }
    },
    Reset() {
      this.paramssearch = {};
      this.dotAdvancedSearch = false;
      this.form.resetFields();
      this.advancedsearch = false;

      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
      });

      this.handlePageChange(this.page);
    },
    Submit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          //this.advancedsearch = false;
          this.fetch({
            page: 1,
            pageSizes: this.pageSize,
            ...values,
          });

          this.paramssearch = values;
          this.advancedsearch = false;
          this.dotAdvancedSearch = true;
        }

        const search = this.search != "" ? { search: this.search } : {};

        this.$router.push({
          query: {
            page: 1,
            pageSizes: this.pageSize,
            ...search,
            ...this.paramssearch,
          },
        });
      });
    },
    onShowSizeChange(current, pageSize) {
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        search: this.search,
        ...params,
      });
      this.handlePageChange(current);
    },
    handlePageChange(page) {
      //checklist reset

      const pager = { ...this.pagination };
      pager.current = page;

      const search = this.search != "" ? { search: this.search } : {};
      const params = this.paramssearch != undefined ? this.paramssearch : {};

      if (params.search != undefined) {
        delete params.search;
      }

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        ...search,
        ...params,
      });

      this.$router.push({
        query: {
          page: pager.current,
          pageSizes: pager.pageSize,
          ...search,
          ...params,
        },
      });
    },

    handlesTbl(item, sortby) {
      if (this.labels == "") {
        this.labels = item;
      }

      if (this.labels != item) {
        this.labels = item;
        this.click = 0;
      }

      console.log("labels", this.labels);
      this.click += 1;

      if (this.click == 3) {
        this.click = 0;
      }

      let sorting = {
        field: item,
        order: sortby[this.click],
      };
      this.handleTableChange(this.page, "", sorting);
      if (item == "nama_peserta" || item == "no_peserta") {
        this.columns[0].customSort = sortby[this.click];
        this.columns[0].choseSort = item;
      }

      if (item == "tanggal_keluar" || item == "tanggal_masuk") {
        this.columns[5].customSort = sortby[this.click];
        this.columns[5].choseSort = item;
      }

      //set columnya ke sorter

      //console.log(this.columns[1].choseSort);
    },
    handleTableChange(page, filter, sorter) {
      if (sorter.field != "nama_peserta" || sorter.field != "no_peserta") {
        this.columns[0].customSort = "";
      }

      if (sorter.field != "tanggal_keluar" || sorter.field != "tanggal_masuk") {
        this.columns[5].customSort = "";
      }

      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;

      this.pagination = pager;

      if (params.search != undefined) {
        delete params.search;
      }

      console.log(pager);
      this.fetch({
        page: pager.page,
        pageSizes: pager.pageSize,
        search: this.search,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...params,
      });

      this.$router.push({
        page: pager.current,
        pageSizes: pager.pageSize,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...params,
      });
    },
    advanced(e) {
      //console.log(this.$refs.buttonadvanced.$el.offsetTop);

      this.advancedsearch = !this.advancedsearch;
      this.top = this.$refs.buttonadvanced.$el.offsetTop + 45;
    },

    closeadvance(e) {
      if (e.target._prevClass == "advancedsearch-dropdown") {
        this.advancedsearch = false;
      }
    },

    fetch(params = {}) {
      //console.log(params);
      this.loading = true;
      this.$store
        .dispatch("getDataClaim", {
          ...params,
        })
        .then((response) => {
          this.loading = false;
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          pagination.pageSize = response.data.pageSize;
          pagination.page = response.data.page;

          this.total = pagination.total;
          this.data = response.data.data;
          this.pagination = pagination;
          this.model = [];
        });
    },

    isDeselect(e) {
      this.checkeds = false;
      if (this.model == []) {
        this.enableds = true;
      } else {
        if (this.model.filter((keys) => keys == true).length == 0) {
          this.enableds = true;
        } else {
          this.enableds = false;
        }
      }
    },
    isCheckAll(e) {
      Object.assign(this, {
        model: e.target.checked ? this.data.map((i) => true) : [],
      });

      this.enableds = e.target.checked ? false : true;
    },

    onSearch(value) {
      this.search = value;

      const search = this.search != "" ? { search: this.search } : {};
      this.fetch({
        page: 1,
        pageSizes: this.pageSize,
        ...search,
      });

      this.$router.push({
        query: {
          page: 1,
          pageSizes: this.pageSize,
          ...search,
        },
      });
    },
  },
};
</script>
