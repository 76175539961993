<template>
  <div>
    <a-modal
      title="Konfirmasi"
      v-model="modals"
      :centered="true"
      width="1351px"
      height="715"
      :footer="null"
    >
      <div>
        <a-form :form="form" @submit="Submit">
          <a-row :gutter="[15, 15]">
            <a-col :lg="8">
              <div class="claimcard" :style="`min-height:${heightCardForm}px`">
                <h1>Informasi Peserta</h1>
                <a-row>
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Nama Peserta</label>
                        <p>{{ data.nama_peserta }}</p>
                      </div>

                      <div>
                        <label>Nama Karyawan</label>
                        <p
                          v-if="
                            action == 'Edit' ||
                            action == 'Edit ADD' ||
                            action == 'Edit CHANGE'
                          "
                        >
                          {{
                            data.status == "E"
                              ? data.nama_peserta
                              : data.nama_karyawan
                          }}
                        </p>
                        <p v-else>
                          {{
                            data.status == "E"
                              ? data.nama_peserta
                              : data.nama_karyawan.label.split("-")[0]
                          }}
                        </p>
                      </div>
                      <div>
                        <label>Jenis Kelamin</label>
                        <p v-if="data.jenis_kelamin == 'F'">
                          <a-icon type="woman" :style="{ color: '#DF5684' }" />
                          Wanita
                        </p>
                        <p v-else>
                          <a-icon type="man" />
                          Pria
                        </p>
                      </div>
                    </div>
                  </a-col>
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>NIK</label>
                        <p>{{ data.nik }}</p>
                      </div>

                      <div>
                        <label>Tgl Lahir</label>
                        <p>
                          <a-icon type="calendar" />
                          {{ moment(data.tgl_lahir).format("DD MMM YYYY") }}
                        </p>
                      </div>
                      <div>
                        <label>Status</label>
                        <p>
                          {{
                            data.status == "E"
                              ? "Employee"
                              : data.status == "S"
                              ? "Spouse"
                              : "Child"
                          }}
                        </p>
                      </div>
                    </div>
                  </a-col>

                  <a-col :lg="24">
                    <div class="_thr profileplan">
                      <label>Plan</label>
                      <div>
                        <div v-if="data.plan == undefined">
                          <ul>
                            <li v-if="data.othersplan[0] != undefined">
                              <img :src="require('@/assets/img/icon/ri.svg')" />
                              {{ data.othersplan[0].plan }}
                            </li>
                            <li v-if="data.othersplan[1] != undefined">
                              <img :src="require('@/assets/img/icon/rj.svg')" />
                              {{ data.othersplan[1].plan }}
                            </li>
                            <li v-if="data.othersplan[2] != undefined">
                              <img :src="require('@/assets/img/icon/km.svg')" />
                              {{ data.othersplan[2].plan }}
                            </li>
                            <li v-if="data.othersplan[3] != undefined">
                              <img :src="require('@/assets/img/icon/rg.svg')" />
                              {{ data.othersplan[3].plan }}
                            </li>
                            <li v-if="data.othersplan[4] != undefined">
                              <img :src="require('@/assets/img/icon/rb.svg')" />
                              {{ data.othersplan[4].plan }}
                            </li>
                          </ul>
                        </div>

                        <div v-else>
                          <ul>
                            <li
                              v-for="(item, i) in data.othersplan[0].benefit
                                .substring(
                                  0,
                                  data.othersplan[0].benefit.length - 2
                                )
                                .split(', ')"
                              :key="i"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/icon/${item
                                    .split(' ')[0]
                                    .toLowerCase()}.svg`)
                                "
                              />
                              {{ item }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </a-col>
                  <span v-if="data.benefit !== undefined">
                    <a-col
                      :span="12"
                      v-for="(item, i) in data.benefit"
                      :key="i"
                    >
                      <div class="sec-data-pasien">
                        <div>
                          <label>{{ item.label }}</label>
                          <p>
                            {{
                              `${data[item.variable]}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </a-col>
                  </span>
                </a-row>
              </div>
            </a-col>
            <a-col :lg="8">
              <div class="claimcard" :style="`min-height:${heightCardForm}px`">
                <h1>Bank Account</h1>
                <a-row>
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Nama Bank</label>
                        <p>{{ data.nama_bank }}</p>
                      </div>
                      <div>
                        <label>No.Rekening</label>
                        <p>{{ data.no_rekening }}</p>
                      </div>
                      <div>
                        <label>Nama Pemilik Rekening</label>
                        <p>{{ data.nama_rekening }}</p>
                      </div>
                    </div>
                  </a-col>
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>No.Telp</label>
                        <p>{{ data.no_telp }}</p>
                      </div>

                      <div>
                        <label>Alamat Email</label>
                        <p>{{ data.email }}</p>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-col>

            <a-col :lg="8">
              <div class="claimcard" :style="`min-height:${heightCardForm}px`">
                <h1>Company Information</h1>

                <a-row>
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Nama Group</label>
                        <p>{{ data.group }}</p>
                      </div>
                      <div>
                        <label>Nama Perusahaan</label>
                        <p>{{ data.name_perusahaan_real }}</p>
                      </div>
                    </div>
                  </a-col>
                  <a-col :lg="12">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Cabang</label>
                        <p>{{ data.cabang }}</p>
                      </div>

                      <div>
                        <label>Sub Cabang I</label>
                        <p>{{ data.sub_cabang_1 }}</p>
                      </div>

                      <div>
                        <label>Sub Cabang II</label>
                        <p>{{ data.sub_cabang_2 }}</p>
                      </div>
                    </div>
                  </a-col>

                  <a-col :lg="24">
                    <div class="sec-data-pasien">
                      <div>
                        <label>Keterangan</label>
                        <p>
                          {{ data.keterangan == null ? "-" : data.keterangan }}
                        </p>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
          <div ref="heightCardForm">
            <div v-if="data.tanggungan != undefined">
              <div v-if="data.tanggungan.length > 0">
                <a-row :gutter="[15, 15]">
                  <a-col :lg="24">
                    <div class="claimcard">
                      <h1>Tanggungan</h1>
                      <div class="tabletanggunga">
                        <table>
                          <thead>
                            <tr>
                              <th>Nama Peserta</th>
                              <th>Tgl Lahir</th>
                              <th>Jenis Kelamin</th>
                              <th>Status</th>
                              <th>Plan</th>
                              <th>Nama Bank</th>
                              <th>No.Rekening</th>
                              <th>Nama Pemilik Rekening</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in data.tanggungan" :key="i">
                              <td>{{ item.nama_peserta_tanggungan }}</td>
                              <td>
                                <a-icon type="calendar" />
                                {{
                                  moment(item.tgl_lahir_tanggungan).format(
                                    "DD MMM YYYY"
                                  )
                                }}
                              </td>
                              <td>
                                <a-icon
                                  type="woman"
                                  style="color: #df568 !important"
                                  v-if="item.jenis_kelamin_tanggungan == 'F'"
                                />
                                <a-icon type="man" v-else />
                                {{
                                  item.jenis_kelamin_tanggungan == "F"
                                    ? "Wanita"
                                    : "Pria"
                                }}
                              </td>
                              <td>
                                {{
                                  item.status_tanggungan == "S"
                                    ? "Spouse"
                                    : "Children"
                                }}
                              </td>
                              <td>
                                <div class="benefitindependent">
                                  <img
                                    :src="
                                      require('@/assets/img/icon/' +
                                        item.other_plan_tanggungan.label
                                          .split(' ')[1]
                                          .toLowerCase() +
                                        '.svg')
                                    "
                                  />

                                  {{ item.other_plan_tanggungan.label }}
                                </div>
                              </td>
                              <td>
                                {{
                                  item.nama_bank_tanggungan == null
                                    ? "-"
                                    : item.nama_bank_tanggungan
                                }}
                              </td>
                              <td>
                                {{
                                  item.no_rekening_tanggungan == null
                                    ? "-"
                                    : item.no_rekening_tanggungan
                                }}
                              </td>
                              <td>
                                {{
                                  item.rekening_atas_nama_tanggungan == null
                                    ? "-"
                                    : item.rekening_atas_nama_tanggungan
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
            <a-row :gutter="[15, 15]">
              <a-col :lg="8" :offset="16">
                <a-form-item label="Tgl Efektif" v-if="action == 'Edit'">
                  <a-date-picker
                    style="width: 100%"
                    autocomplete="off"
                    v-decorator="[
                      'tgl_efektif',
                      {
                        rules: [
                          { required: true, message: 'Field ini harus diisi' },
                        ],
                      },
                    ]"
                    placeholder="Input disini"
                    format="DD MMM YYYY"
                  />
                </a-form-item>

                <a-form-item label="Tgl Efektif" v-else>
                  <a-date-picker
                    :disabled-date="
                      action == 'Create' || action == 'Edit ADD'
                        ? disabledDate
                        : false
                    "
                    style="width: 100%"
                    autocomplete="off"
                    v-decorator="[
                      'tgl_join',
                      {
                        rules: [
                          { required: true, message: 'Field ini harus diisi' },
                        ],
                      },
                    ]"
                    placeholder="Input disini"
                    format="DD MMM YYYY"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <div class="tgljoin">
            <a-button type="primary" html-type="submit" :loading="loading"
              >Ok</a-button
            >
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormConfirm" });
  },
  data() {
    return {
      modals: false,
      heightCardForm: 0,
      timeouts: null,
      time: 500,
      data: {},
      loading: false,
      action: "",
      datedisable: 0,
      tanggungan: [],
    };
  },

  created() {
    //console.log(this.heightCardForm);
  },
  mounted() {
    document.body.classList.add("ModalCustom");
  },

  methods: {
    moment,
    getTanggungan() {
      this.$store
        .dispatch("getTanggunganTemporary", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.tanggungan = response.data.data;
        });
    },
    callHeight() {
      //if()
      let a = this.$refs.heightCardForm.offsetTop - 94;
      this.heightCardForm = a;
      console.log("ref", this.$refs.heightCardForm);
      //return;
    },
    Show(e, action) {
      console.log("action", action);
      this.action = action;
      this.data = e;
      this.modals = true;

      this.$nextTick(() => {
        this.callHeight();
        console.log(this.data);
      });

      if (action == "Create") {
        this.datedisabled();
      }

      if (action == "Edit CHANGE" || action == "Edit ADD") {
        if (this.data.status == "E") {
          this.getTanggungan();
        }
      }
    },

    EditPesertaTanggungan(
      values,
      id_peserta,
      nama_peserta,
      jenis_kelamin,
      tgl_lahir,
      status
    ) {
      let filteredObject = Object.keys(this.data)
        .filter((key) => key.includes("limit")) // Ambil kunci yang mengandung "limit"
        .reduce((acc, key) => {
          acc[key] = this.data[key]; // Bangun objek baru hanya dengan kunci tersebut
          return acc;
        }, {});

      this.$store
        .dispatch(
          this.action == "Edit CHANGE"
            ? "ChangeChangePesertaAntrian"
            : "ChangeAddPesertaAntrian",
          {
            ...filteredObject,
            id_peserta: id_peserta,
            nama_group: this.$route.query.id_job_order,
            nama_perusahaan: this.data.nama_perusahaan,
            nama_peserta: status == "E" ? this.data.nama_peserta : nama_peserta,
            nik: this.data.nik,
            id_karyawan: this.data.id_karyawan,
            nama_karyawan: this.data.nama_peserta,
            tgl_lahir: moment(tgl_lahir).format("YYYY-MM-DD"),
            jenis_kelamin: jenis_kelamin,
            status: status,
            kategori_usia: this.data.kategori_usia,
            other_plan: this.data.plan,
            nama_bank: this.data.nama_bank,
            no_rekening: this.data.no_rekening,
            rekening_atas_nama: this.data.nama_rekening,
            no_telp: this.data.no_telp,
            alamat_email: this.data.email,
            cabang: this.data.cabang,
            sub_cabang_satu: this.data.sub_cabang_1,
            sub_cabang_dua: this.data.sub_cabang_2,
            ket: this.data.keterangan,
            [this.action == "Edit Change" ? "tgl_efektif" : "tgl_join"]:
              this.action == "Edit Change"
                ? `${moment(values.tgl_efektif).format("YYYY-MM-DD")}`
                : `${moment(values.tgl_join).format("YYYY-MM-DD")}`,
          }
        )
        .then((response) => {
          console.log("done");
          this.$emit("fetch");
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    disabledDate(current) {
      //console.log(current)
      return (
        current &&
        current <= moment().subtract(parseInt(this.datedisable) + 1, "days")
      );
    },
    datedisabled() {
      this.$store
        .dispatch("getDateDisabled", {
          movement: "ADD",
          job_order: this.$route.params.id,
        })
        .then((response) => {
          this.datedisable = response.data.data.ketentuan;
        });
    },
    Submit(e) {
      e.preventDefault();

      let filteredObject = Object.keys(this.data)
        .filter((key) => key.includes("limit")) // Ambil kunci yang mengandung "limit"
        .reduce((acc, key) => {
          acc[key] = this.data[key]; // Bangun objek baru hanya dengan kunci tersebut
          return acc;
        }, {});

      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          if (this.action == "Edit") {
            this.$store
              .dispatch("ChangePeserta", {
                ...filteredObject,
                id_peserta: this.$route.params.idpeserta,
                nama_group: this.$route.params.id,
                nama_perusahaan: this.data.nama_perusahaan,
                nama_peserta: this.data.nama_peserta,
                nik: this.data.nik,
                id_karyawan: this.data.id_karyawan,
                nama_karyawan:
                  this.data.status == "E"
                    ? this.data.nama_peserta
                    : this.data.nama_karyawan,
                tgl_lahir: moment(this.data.tgl_lahir).format("YYYY-MM-DD"),
                jenis_kelamin: this.data.jenis_kelamin,
                status: this.data.status,
                kategori_usia: this.data.kategori_usia,
                other_plan: this.data.plan,
                nama_bank: this.data.nama_bank,
                no_rekening: this.data.no_rekening,
                rekening_atas_nama: this.data.nama_rekening,
                no_telp: this.data.no_telp,
                alamat_email: this.data.email,
                cabang: this.data.cabang,
                sub_cabang_satu: this.data.sub_cabang_1,
                sub_cabang_dua: this.data.sub_cabang_2,
                ket: this.data.keterangan,
                tgl_efektif: moment(values.tgl_efektif).format("YYYY-MM-DD"),
              })
              .then((response) => {
                this.loading = false;
                this.modals = false;
                this.$notification.success({
                  message: response.data.message,
                });
                this.$router.push({ name: "ListQueAntrianData" });
              })
              .catch((error) => {
                this.loading = false;
              });
          } else if (this.action == "Edit ADD") {
            this.tanggungan.map((key) => {
              this.EditPesertaTanggungan(
                values,
                key.id,
                key.nama_peserta,
                key.jenis_kelamin,
                key.tgl_lahir,
                key.status
              );
            });

            // this.$store
            //   .dispatch("ChangeAddPesertaAntrian", {
            //     id_peserta: this.$route.params.id,
            //     nama_group: this.$route.query.id_job_order,
            //     nama_perusahaan: this.data.nama_perusahaan,
            //     nama_peserta: this.data.nama_peserta,
            //     nik: this.data.nik,
            //     id_karyawan: this.data.id_karyawan,
            //     nama_karyawan:
            //       this.data.status == "E"
            //         ? this.data.nama_peserta
            //         : this.data.nama_karyawan,
            //     tgl_lahir: moment(this.data.tgl_lahir).format("YYYY-MM-DD"),
            //     jenis_kelamin: this.data.jenis_kelamin,
            //     status: this.data.status,
            //     kategori_usia: this.data.kategori_usia,
            //     other_plan: this.data.plan,
            //     nama_bank: this.data.nama_bank,
            //     no_rekening: this.data.no_rekening,
            //     rekening_atas_nama: this.data.nama_rekening,
            //     no_telp: this.data.no_telp,
            //     alamat_email: this.data.email,
            //     cabang: this.data.cabang,
            //     sub_cabang_satu: this.data.sub_cabang_1,
            //     sub_cabang_dua: this.data.sub_cabang_2,
            //     ket: this.data.keterangan,
            //     tgl_join: moment(this.data.tgl_join).format("YYYY-MM-DD"),
            //   })
            //   .then((response) => {
            //     this.loading = false;
            //     this.modals = false;
            //     this.$notification.success({
            //       message: response.data.message,
            //     });
            //     this.$router.push({ name: "ListQueAntrianData" });
            //   })
            //   .catch((error) => {
            //     this.loading = false;
            //   });
          } else if (this.action == "Edit CHANGE") {
            if (this.data.status == "E") {
              this.tanggungan.map((key) => {
                this.EditPesertaTanggungan(
                  values,
                  key.id,
                  key.nama_peserta,
                  key.jenis_kelamin,
                  key.tgl_lahir,
                  key.status
                );
              });

              this.$emit("fetch");

              //console.log("Employee");
            } else {
              this.$store
                .dispatch("ChangeChangePesertaAntrian", {
                  ...filteredObject,
                  id_peserta: this.$route.params.id,
                  nama_group: this.$route.query.id_job_order,
                  nama_perusahaan: this.data.nama_perusahaan,
                  nama_peserta: this.data.nama_peserta,
                  nik: this.data.nik,
                  id_karyawan: this.data.id_karyawan,
                  nama_karyawan:
                    this.data.status == "E"
                      ? this.data.nama_peserta
                      : this.data.nama_karyawan,
                  tgl_lahir: moment(this.data.tgl_lahir).format("YYYY-MM-DD"),
                  jenis_kelamin: this.data.jenis_kelamin,
                  status: this.data.status,
                  kategori_usia: this.data.kategori_usia,
                  other_plan: this.data.plan,
                  nama_bank: this.data.nama_bank,
                  no_rekening: this.data.no_rekening,
                  rekening_atas_nama: this.data.nama_rekening,
                  no_telp: this.data.no_telp,
                  alamat_email: this.data.email,
                  cabang: this.data.cabang,
                  sub_cabang_satu: this.data.sub_cabang_1,
                  sub_cabang_dua: this.data.sub_cabang_2,
                  ket: this.data.keterangan,
                  tgl_efektif: moment(values.tgl_efektif).format("YYYY-MM-DD"),
                })
                .then((response) => {
                  this.loading = false;
                  this.modals = false;
                  this.$notification.success({
                    message: response.data.message,
                  });
                  this.$router.push({ name: "ListQueAntrianData" });
                })
                .catch((error) => {
                  this.loading = false;
                });
            }
          } else {
            this.$store
              .dispatch("InsertPeserta", {
                ...filteredObject,
                nama_peserta: this.data.nama_peserta,
                nik: this.data.nik,
                nama_karyawan:
                  this.data.status == "E"
                    ? this.data.nama_peserta
                    : this.data.nama_karyawan.label.split("-")[0],
                id_karyawan:
                  this.data.nama_karyawan == null
                    ? null
                    : this.data.nama_karyawan.key,
                tgl_lahir: moment(this.data.tgl_lahir).format("YYYY-MM-DD"),
                jenis_kelamin: this.data.jenis_kelamin,
                status: this.data.status,
                kategori_usia: this.data.kategori_usia,
                other_plan: this.data.plan,
                nama_bank: this.data.nama_bank,
                no_rekening: this.data.no_rekening,
                rekening_atas_nama: this.data.nama_rekening,
                no_telp: this.data.no_telp,
                alamat_email: this.data.email,
                nama_group: this.$route.params.id,
                nama_perusahaan: this.data.nama_perusahaan,
                cabang: this.data.cabang,
                sub_cabang_satu: this.data.sub_cabang_1,
                sub_cabang_dua: this.data.sub_cabang_2,
                ket: this.data.keterangan,
                tgl_join: moment(values.tgl_join).format("YYYY-MM-DD"),

                tanggungan:
                  this.data.tanggungan.length > 0
                    ? this.data.tanggungan.map((key) => ({
                        nama_peserta_tanggungan: key.nama_peserta_tanggungan,
                        tgl_lahir_tanggungan: key.tgl_lahir_tanggungan,
                        jenis_kelamin_tanggungan: key.jenis_kelamin_tanggungan,
                        status_tanggungan: key.status_tanggungan,
                        kategori_usia_tanggungan: key.kategori_usia_tanggungan,
                        other_plan_tanggungan: key.other_plan_tanggungan.key,
                        nama_bank_tanggungan:
                          key.nama_bank_tanggungan == undefined
                            ? null
                            : key.nama_bank_tanggungan,
                        no_rekening_tanggungan:
                          key.no_rekening_tanggungan == undefined
                            ? null
                            : key.no_rekening_tanggungan,
                        rekening_atas_nama_tanggungan:
                          key.rekening_atas_nama_tanggungan == undefined
                            ? null
                            : key.rekening_atas_nama_tanggungan,
                      }))
                    : [],
              })
              .then((response) => {
                this.loading = false;
                this.modals = false;
                this.$notification.success({
                  message: response.data.message,
                });
                this.$router.push({ name: "ListQueAntrianData" });
              })
              .catch((error) => {
                this.loading = false;
              });
          }
          //end if
        }
      });
    },
  },
};
</script>

<style>
.ModalCustom .ant-modal-content {
  padding: 2px 5px !important;
}

.ModalCustom .ant-modal-header {
  border-radius: 24px !important;
}

.ModalCustom .ant-modal-close-x {
  margin-right: 20px;
}
</style>
