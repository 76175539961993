var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-view',{scopedSlots:_vm._u([{key:"titlePesertaBreadcrumb",fn:function({ messageProps }){return [_c('div',[_c('div',[_c('h1',[_vm._v(_vm._s(messageProps))])]),_vm._t("onlybreadcrumb")],2)]}}],null,true)},[_c('template',{slot:"onlybreadcrumb"},[_c('div',[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.data.group))])]),_vm._t("onlybreadcrumb")],2)]),_c('template',{slot:"titlebreadcrumb"},[_c('div',[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.data.group))]),_c('div',{staticClass:"iconhealth"},[_c('img',{attrs:{"src":require('@/assets/img/icon/health.svg')}}),_vm._v(" "+_vm._s(_vm.data.jenis_asuransi)+" ")])]),_vm._t("onlybreadcrumb")],2)]),_c('template',{slot:"benefitheader"},[_c('div',{staticClass:"titleheadright"},[(_vm.$route.query.nama_perusahaan != undefined)?_c('div',{staticClass:"_thr"},[_c('label',[_vm._v("Perusahaan")]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$route.query.nama_perusahaan))])])]):_vm._e(),_c('div',{staticClass:"_thr"},[_c('label',[_vm._v("Periode Polis")]),_c('div',[_c('img',{attrs:{"src":require('@/assets/img/icon/calendar.svg')}}),_c('p',[_vm._v(_vm._s(`${_vm.data.periode_awal} - ${_vm.data.periode_akhir}`))])])]),_c('div',{staticClass:"_thr"},[_c('label',[_vm._v("Asuransi")]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.data.asuransi))])])]),_c('div',{staticClass:"_thr"},[_c('label',[_vm._v("Benefit")]),_c('div',[_c('div',[(_vm.data.benefit_existing !== undefined)?_c('ul',_vm._l((JSON.parse(_vm.data.benefit_existing)),function(item,i){return _c('li',{key:i,on:{"click":function($event){return _vm.Information(item)}}},[_c('img',{attrs:{"src":require(`@/assets/img/icon/${item
                        .replace(/\s/g, '')
                        .toLowerCase()}.svg`)}}),_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()])])])])]),_c('template',{slot:"menuall"},[_c('div',{staticClass:"allmenusub"},[_c('ul',[_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Daftar PT' ? 'menusubactive' : '',attrs:{"to":{ name: 'ListDaftarPT' }}},[_vm._v("Daftar PT")])],1),_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Data Peserta' ? 'menusubactive' : '',attrs:{"to":{ name: 'ListDataPeserta' }}},[_vm._v("Data Peserta")])],1),_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Benefit' ? 'menusubactive' : '',attrs:{"to":{ name: 'ListBenefit', query: { plan: 'RI' } }}},[_vm._v("Benefit")])],1),(_vm.ban.indexOf(_vm.$route.params.id) == -1)?_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Ketentuan Umum' ? 'menusubactive' : '',attrs:{"to":{ name: 'ListKetentuanUmum' }}},[_vm._v("Ketentuan Umum")])],1):_vm._e()])])]),_c('template',{slot:"menudatapeserta"},[_c('ul',{staticClass:"menutopdatapeserta"},[_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Data Peserta' && _vm.$route.query.menu == null
                ? '_activemenutoppeserta'
                : '',attrs:{"to":{
              name: 'ListDataPeserta',
              query: {
                ...(_vm.$route.query.id_perusahaan == undefined
                  ? null
                  : {
                      id_perusahaan: _vm.$route.query.id_perusahaan,
                      nama_perusahaan: _vm.$route.query.nama_perusahaan,
                    }),
              },
            },"disabled":_vm.loadpeserta}},[_vm._v("Aktif")])],1),_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Data Peserta' &&
              _vm.$route.query.menu == 'nonaktif'
                ? '_activemenutoppeserta'
                : '',attrs:{"to":{
              name: 'ListDataPeserta',
              query: {
                menu: 'nonaktif',
                ...(_vm.$route.query.id_perusahaan == undefined
                  ? null
                  : {
                      id_perusahaan: _vm.$route.query.id_perusahaan,
                      nama_perusahaan: _vm.$route.query.nama_perusahaan,
                    }),
              },
            },"disabled":_vm.loadpeserta}},[_vm._v("Non Aktif")])],1),_c('li',[_c('router-link',{class:_vm.$route.meta.title == 'Data Peserta' &&
              _vm.$route.query.menu == 'coming-movement'
                ? '_activemenutoppeserta'
                : '',attrs:{"to":{
              name: 'ListDataPeserta',
              query: {
                menu: 'coming-movement',
                ...(_vm.$route.query.id_perusahaan == undefined
                  ? null
                  : {
                      id_perusahaan: _vm.$route.query.id_perusahaan,
                      nama_perusahaan: _vm.$route.query.nama_perusahaan,
                    }),
              },
            },"disabled":_vm.loadpeserta}},[_vm._v("Coming Movement")])],1)])])],2),_c('Plan',{ref:"plan"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }