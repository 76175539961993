var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"zoomdashboard"},[_c('div',[_c('div',{staticClass:"homeleftinformation"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/img/logo-login.png')}}),_c('h3',{staticStyle:{"font-weight":""}},[_vm._v(" "+_vm._s(_vm.greeting())+" "),(_vm.greeting() == 'Selamat Malam ')?_c('img',{staticStyle:{"width":"35px"},attrs:{"src":require('@/assets/img/icon/nighticon.svg')}}):_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('@/assets/img/icon/morningicon.svg')}})]),_c('div',[_c('div',{staticClass:"nameuser"},[_c('h1',[_vm._v(" "+_vm._s(_vm.profile.user.nama_lengkap.split(" ")[0])+" "+_vm._s(_vm.profile.user.nama_lengkap.split(" ")[1] != undefined ? _vm.profile.user.nama_lengkap.split(" ")[1] : "")+" ")]),_c('div',{staticStyle:{"display":"flex"}},[_c('router-link',{attrs:{"to":{ name: 'ProfileUser' }}},[_c('img',{attrs:{"src":require('@/assets/img/icon/setting.svg')}})]),_c('div',{staticClass:"notifhome"},[_c('Notif')],1)],1)])]),_c('div',{staticClass:"dashboardpt"},[_c('router-link',{attrs:{"to":"#"}},[_c('span',[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.perusahaan.nama_perusahaan))]),_c('a-icon',{attrs:{"type":"right"}})],1),_c('div',{staticClass:"topoverlayperusahaan"},[_c('a-input-search',{attrs:{"placeholder":"Cari","allowClear":""},on:{"change":_vm.onLeftSearch}}),_c('simplebar',{staticClass:"simplebarcustom",staticStyle:{"max-height":"150px"},attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',[_c('router-link',{staticClass:"simpleklikPT",attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.changePT('all', 'all', 'all', 'all')}}},[_vm._v(" All ")])],1),_vm._l((_vm.FilterSearch),function(item,i){return _c('div',{key:i},[_c('router-link',{staticClass:"simpleklikPT",attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.changePT(
                          item.id_job_order_perusahaan,
                          item.nama_perusahaan,
                          item.id_contact_perusahaan,
                          item.benefit_existing
                        )}}},[_vm._v(" "+_vm._s(item.nama_perusahaan)+" ")])],1)})],2)],1)])],1),_c('div',{staticClass:"_thr homeplan"},[_c('label',[_vm._v("Plan")]),_c('div',[_c('div',[(_vm.perusahaan.benefit_existing !== 'all')?_c('ul',_vm._l((JSON.parse(
                      _vm.perusahaan.benefit_existing
                    )),function(item,i){return _c('li',{key:i,on:{"click":function($event){return _vm.Information(item)}}},[_c('img',{attrs:{"src":require('@/assets/img/icon/' +
                          item.replace(/\s/g, '').toLowerCase() +
                          '.svg')}}),_vm._v(" "+_vm._s(item)+" ")])}),0):_c('ul',_vm._l((_vm.getAllPlan),function(item,i){return _c('li',{key:i,on:{"click":function($event){return _vm.Information(item)}}},[_c('img',{attrs:{"src":require('@/assets/img/icon/' +
                          item.replace(/\s/g, '').toLowerCase() +
                          '.svg')}}),_vm._v(" "+_vm._s(item)+" ")])}),0)])])])])])]),_c('div',[_c('div',{staticClass:"zoomcarddashboard"},[_c('div',[_c('div',{staticClass:"_dashboard"},[_c('ClaimPending',[_c('template',{slot:"titledsahboard"},[_c('div',{staticStyle:{"background":"rgb(238 88 67 / 94%) !important","color":"#fff"},on:{"click":function($event){return _vm.dashboardclick('Klaim Pending', _vm.perusahaan)}}},[_c('img',{staticStyle:{"filter":"brightness(0) invert(1)"},attrs:{"src":require('@/assets/img/icon/pdg.svg')}}),_c('h3',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.totalPending))]),_c('div',[_vm._v("Klaim Pending")])])]),_c('template',{slot:"sortingdashboard"},[_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomRight"}},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":(e) => e.preventDefault()}},[_c('img',{style:(_vm.clickPending == 1 ? '' : 'filter: grayscale(100%)'),attrs:{"src":require('@/assets/img/icon/sortup.svg')}}),_c('img',{style:(_vm.clickPending == 2 ? '' : 'filter: grayscale(100%)'),attrs:{"src":require('@/assets/img/icon/sortdown.svg')}})]),_c('div',{staticClass:"dropdownoverlay",attrs:{"slot":"overlay"},slot:"overlay"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPending('Tanggal Masuk', 'tanggal_masuk')}}},[(
                              _vm.clickPending == 1 &&
                              _vm.sortByPendinglabel == 'Tanggal Masuk'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPending == 2 &&
                              _vm.sortByPendinglabel == 'Tanggal Masuk'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Tanggal Masuk")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPending('Tanggal Keluar', 'tanggal_keluar')}}},[(
                              _vm.clickPending == 1 &&
                              _vm.sortByPendinglabel == 'Tanggal Keluar'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPending == 2 &&
                              _vm.sortByPendinglabel == 'Tanggal Keluar'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Tanggal Keluar")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPending('Status Claim', 'status_claim')}}},[(
                              _vm.clickPending == 1 &&
                              _vm.sortByPendinglabel == 'Status Claim'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPending == 2 &&
                              _vm.sortByPendinglabel == 'Status Claim'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Status Claim")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPending('Created', 'created_at')}}},[(
                              _vm.clickPending == 1 &&
                              _vm.sortByPendinglabel == 'Created'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPending == 2 &&
                              _vm.sortByPendinglabel == 'Created'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Created")])],1)])])])],1),_c('template',{slot:"table"},[_c('a-table',{staticClass:"dashboardtable",attrs:{"rowKey":(record) => record.id,"columns":_vm.columns,"dataSource":_vm.dataPending,"pagination":false,"loading":_vm.loading1,"scroll":_vm.widthtable >= 602
                      ? { y: _vm.heighttable }
                      : { x: _vm.widthtable, y: _vm.heighttable }},on:{"change":_vm.handleTableChangePending},scopedSlots:_vm._u([{key:"no_claim",fn:function(text, record){return [_c('router-link',{attrs:{"to":{
                        name: 'DetailKlaim',
                        params: { id: record.id },
                      }}},[_vm._v(_vm._s(text)+" "),_c('a-icon',{staticStyle:{"font-size":"11px !important","font-weight":"500","padding-left":"10px"},attrs:{"type":"right"}})],1)]}},{key:"nama_pasien",fn:function(text, record){return [_c('div',{attrs:{"title":text}},[_c('router-link',{attrs:{"to":{
                          name: 'DetailPeserta',
                          params: {
                            id: record.id_job_order,
                            idpeserta: record.id_peserta,
                          },
                        }}},[_c('span',{staticStyle:{"white-space":"nowrap","display":"inline-block","width":"100px","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(text))]),_c('a-icon',{staticStyle:{"font-size":"11px !important","font-weight":"bolder !important","padding-left":"10px","vertical-align":"text-top"},attrs:{"type":"right"}})],1)],1)]}},{key:"jumlah_diajukan",fn:function(text, record){return [(text == null)?_c('div',[_vm._v("-")]):_c('div',[_c('div',{staticStyle:{"color":"#060d17"}},[_vm._v(" "+_vm._s(record.mata_uang)+" "+_vm._s(_vm.numberWithCommas(text))+" ")])])]}},{key:"produk_plan",fn:function(text){return [(text != 'NON PLAN/PRODUCT')?_c('div',{staticClass:"iconpeserta"},[_c('img',{attrs:{"src":require(`@/assets/img/icon/${text
                            .replace(/\s/g, '')
                            .toLowerCase()}.svg`)}}),_vm._v(" "+_vm._s(text)+" ")]):_c('div',[_vm._v("-")])]}}])},[_c('template',{slot:"product"},[_vm._v(" Product"),_c('br'),_vm._v("Plan ")]),_c('template',{slot:"jumlah_diajukan"},[_vm._v(" Jumlah yang"),_c('br'),_vm._v("Diajukan ")]),_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(null)+" ")])],2)],1)],2)],1),_c('div',{ref:"KlaimProses",staticClass:"_dashboard"},[_c('ClaimPending',[_c('template',{slot:"titledsahboard"},[_c('div',{staticStyle:{"background":"rgb(76 136 255 / 83%) !important","color":"#fff"},on:{"click":function($event){return _vm.dashboardclick('Klaim Proses', _vm.perusahaan)}}},[_c('img',{staticStyle:{"filter":"brightness(0) invert(1)"},attrs:{"src":require('@/assets/img/icon/sdoa.svg')}}),_c('h3',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.totalKlaimProses))]),_c('div',[_vm._v("Klaim Sedang di-review Asuransi")])])]),_c('template',{slot:"sortingdashboard"},[_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomRight"}},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":(e) => e.preventDefault()}},[_c('img',{style:(_vm.clickProses == 1 ? '' : 'filter: grayscale(100%)'),attrs:{"src":require('@/assets/img/icon/sortup.svg')}}),_c('img',{style:(_vm.clickProses == 2 ? '' : 'filter: grayscale(100%)'),attrs:{"src":require('@/assets/img/icon/sortdown.svg')}})]),_c('div',{staticClass:"dropdownoverlay",attrs:{"slot":"overlay"},slot:"overlay"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByProses('Tanggal Masuk', 'tanggal_masuk')}}},[(
                              _vm.clickProses == 1 &&
                              _vm.sortByProseslabel == 'Tanggal Masuk'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickProses == 2 &&
                              _vm.sortByProseslabel == 'Tanggal Masuk'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Tanggal Masuk")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByProses('Tanggal Keluar', 'tanggal_keluar')}}},[(
                              _vm.clickProses == 1 &&
                              _vm.sortByProseslabel == 'Tanggal Keluar'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickProses == 2 &&
                              _vm.sortByProseslabel == 'Tanggal Keluar'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Tanggal Keluar")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByProses('Status Claim', 'status_claim')}}},[(
                              _vm.clickProses == 1 &&
                              _vm.sortByProseslabel == 'Status Claim'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickProses == 2 &&
                              _vm.sortByProseslabel == 'Status Claim'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Status Claim")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByProses('Created', 'status_claim')}}},[(
                              _vm.clickProses == 1 &&
                              _vm.sortByProseslabel == 'Created'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickProses == 2 &&
                              _vm.sortByProseslabel == 'Created'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Created")])],1)])])])],1),_c('template',{slot:"table"},[_c('a-table',{staticClass:"dashboardtable",attrs:{"rowKey":(record) => record.id,"columns":_vm.columns,"dataSource":_vm.data,"pagination":false,"loading":_vm.loading3,"scroll":_vm.widthtable >= 602
                      ? { y: _vm.heighttable }
                      : { x: _vm.widthtable, y: _vm.heighttable }},on:{"change":_vm.handleTableChangeProses},scopedSlots:_vm._u([{key:"no_claim",fn:function(text, record){return [_c('router-link',{attrs:{"to":{ name: 'DetailKlaim', params: { id: record.id } }}},[_vm._v(_vm._s(text)+" "),_c('a-icon',{staticStyle:{"font-size":"11px !important","font-weight":"500","padding-left":"10px"},attrs:{"type":"right"}})],1)]}},{key:"nama_pasien",fn:function(text, record){return [_c('div',{attrs:{"title":text}},[_c('router-link',{attrs:{"to":{
                          name: 'DetailPeserta',
                          params: {
                            id: record.id_job_order,
                            idpeserta: record.id_peserta,
                          },
                        }}},[_c('span',{staticStyle:{"white-space":"nowrap","display":"inline-block","width":"100px","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(text))]),_c('a-icon',{staticStyle:{"font-size":"11px !important","font-weight":"bolder !important","padding-left":"10px","vertical-align":"text-top"},attrs:{"type":"right"}})],1)],1)]}},{key:"jumlah_diajukan",fn:function(text, record){return [(text == null)?_c('div',[_vm._v("-")]):_c('div',[_c('div',{staticStyle:{"color":"#060d17"}},[_vm._v(" "+_vm._s(record.mata_uang)+" "+_vm._s(_vm.numberWithCommas(text))+" ")])])]}},{key:"produk_plan",fn:function(text){return [(text != 'NON PLAN/PRODUCT')?_c('div',{staticClass:"iconpeserta"},[_c('img',{attrs:{"src":require(`@/assets/img/icon/${text
                            .replace(/\s/g, '')
                            .toLowerCase()}.svg`)}}),_vm._v(" "+_vm._s(text)+" ")]):_c('div',[_vm._v("-")])]}}])},[_c('template',{slot:"product"},[_vm._v(" Product"),_c('br'),_vm._v("Plan ")]),_c('template',{slot:"jumlah_diajukan"},[_vm._v(" Jumlah yang"),_c('br'),_vm._v("Diajukan ")]),_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(null)+" ")])],2)],1)],2)],1)]),_c('div',[_c('div',{staticClass:"_dashboard"},[_c('ClaimPending',[_c('template',{slot:"titledsahboard"},[_c('div',{staticStyle:{"background":"rgb(255 153 0 / 81%) !important","color":"#fff"},on:{"click":function($event){return _vm.dashboardclick('Klaim Diproses Ulang', _vm.perusahaan)}}},[_c('img',{staticStyle:{"filter":"brightness(0) invert(1)"},attrs:{"src":require('@/assets/img/icon/mlk.svg')}}),_c('h3',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.totalPUCL))]),_c('div',[_vm._v("Klaim Kekurangan Dokumen")])])]),_c('template',{slot:"sortingdashboard"},[_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomRight"}},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":(e) => e.preventDefault()}},[_c('img',{style:(_vm.clickPUCL == 1 ? '' : 'filter: grayscale(100%)'),attrs:{"src":require('@/assets/img/icon/sortup.svg')}}),_c('img',{style:(_vm.clickPUCL == 2 ? '' : 'filter: grayscale(100%)'),attrs:{"src":require('@/assets/img/icon/sortdown.svg')}})]),_c('div',{staticClass:"dropdownoverlay",attrs:{"slot":"overlay"},slot:"overlay"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPUCL('Tanggal Masuk', 'tanggal_masuk')}}},[(
                              _vm.clickPUCL == 1 &&
                              _vm.sortByPUCLlabel == 'Tanggal Masuk'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPUCL == 2 &&
                              _vm.sortByPUCLlabel == 'Tanggal Masuk'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Tanggal Masuk")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPUCL('Tanggal Keluar', 'tanggal_keluar')}}},[(
                              _vm.clickPUCL == 1 &&
                              _vm.sortByPUCLlabel == 'Tanggal Keluar'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPUCL == 2 &&
                              _vm.sortByPUCLlabel == 'Tanggal Keluar'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v("Tanggal Keluar")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPUCL('Status Claim', 'status_claim')}}},[(
                              _vm.clickPUCL == 1 &&
                              _vm.sortByPUCLlabel == 'Status Claim'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPUCL == 2 &&
                              _vm.sortByPUCLlabel == 'Status Claim'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Status Claim")])],1),_c('li',[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.sortByPUCL('Created', 'status_claim')}}},[(
                              _vm.clickPUCL == 1 && _vm.sortByPUCLlabel == 'Created'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortup.svg')}}):_vm._e(),(
                              _vm.clickPUCL == 2 && _vm.sortByPUCLlabel == 'Created'
                            )?_c('img',{attrs:{"src":require('@/assets/img/icon/sortdown.svg')}}):_vm._e(),_vm._v(" Created")])],1)])])])],1),_c('template',{slot:"table"},[_c('a-table',{staticClass:"dashboardtable",attrs:{"rowKey":(record) => record.id,"columns":_vm.columns,"dataSource":_vm.dataPucl,"pagination":false,"loading":_vm.loading2,"scroll":_vm.widthtable >= 602
                      ? { y: _vm.heighttable }
                      : { x: _vm.widthtable, y: _vm.heighttable }},on:{"change":_vm.handleTableChangePucl},scopedSlots:_vm._u([{key:"no_claim",fn:function(text, record){return [_c('router-link',{attrs:{"to":{
                        name: 'DetailKlaim',
                        params: { id: record.id },
                      }}},[_vm._v(_vm._s(text)+" "),_c('a-icon',{staticStyle:{"font-size":"11px !important","font-weight":"500","padding-left":"10px"},attrs:{"type":"right"}})],1)]}},{key:"nama_pasien",fn:function(text, record){return [_c('div',{attrs:{"title":text}},[_c('router-link',{attrs:{"to":{
                          name: 'DetailPeserta',
                          params: {
                            id: record.id_job_order,
                            idpeserta: record.id_peserta,
                          },
                        }}},[_c('span',{staticStyle:{"white-space":"nowrap","display":"inline-block","width":"100px","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(text))]),_c('a-icon',{staticStyle:{"font-size":"11px !important","font-weight":"bolder !important","padding-left":"10px","vertical-align":"text-top"},attrs:{"type":"right"}})],1)],1)]}},{key:"jumlah_diajukan",fn:function(text, record){return [(text == null)?_c('div',[_vm._v("-")]):_c('div',[_c('div',{staticStyle:{"color":"#060d17"}},[_vm._v(" "+_vm._s(record.mata_uang)+" "+_vm._s(_vm.numberWithCommas(text))+" ")])])]}},{key:"produk_plan",fn:function(text){return [(text != 'NON PLAN/PRODUCT')?_c('div',{staticClass:"iconpeserta"},[_c('img',{attrs:{"src":require(`@/assets/img/icon/${text
                            .replace(/\s/g, '')
                            .toLowerCase()}.svg`)}}),_vm._v(" "+_vm._s(text)+" ")]):_c('div',[_vm._v("-")])]}}])},[_c('template',{slot:"product"},[_vm._v(" Product"),_c('br'),_vm._v("Plan ")]),_c('template',{slot:"jumlah_diajukan"},[_vm._v(" Jumlah yang"),_c('br'),_vm._v("Diajukan ")]),_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(null)+" ")])],2)],1)],2)],1),_c('div',{staticClass:"zoomperubaanperbulan"},[_c('div',{staticClass:"dashboarddmtop",staticStyle:{"overflow":"hidden"},on:{"click":function($event){return _vm.Movementclick('Perbulan', _vm.perusahaan)}}},[_c('h2',[_vm._v("Perubahan Perbulan")]),_c('a-spin',{attrs:{"spinning":_vm.loading4}},[_c('Bar',{attrs:{"chart-data":_vm.dataChart,"chart-options":_vm.options,"height":230}})],1)],1),_c('div',{staticClass:"dashboarddm"},[_c('div',{staticClass:"dashboarddmbottom",on:{"click":function($event){return _vm.Movementclick('Addition', _vm.perusahaan)}}},[_c('img',{attrs:{"src":require('@/assets/img/icon/addition.svg')}}),_c('h3',[_c('a-spin',{attrs:{"spinning":_vm.loading4}},[_vm._v(_vm._s(_vm.today.addition)+" ")])],1),_c('div',[_vm._v("Addition")])]),_c('div',{staticClass:"dashboarddmbottom",on:{"click":function($event){return _vm.Movementclick('Deleted', _vm.perusahaan)}}},[_c('img',{attrs:{"src":require('@/assets/img/icon/deleted.svg')}}),_c('h3',[_c('a-spin',{attrs:{"spinning":_vm.loading4}},[_vm._v(_vm._s(_vm.today.deleted))])],1),_c('div',[_vm._v("Deleted")])])])])])])])]),_c('Plan',{ref:"plan"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }