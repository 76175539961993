<template>
  <div>
    <!-- <router-link to="#1043-HR" @click.native="clicks('#1043-HR')"
      >tes</router-link
    > -->
    <!-- Start Breadcrum dan title -->
    <div class="ContentHeader">
      <div class="HeaderAll">
        <slot name="titlebreadcrumb"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <!-- End Breadcrumb dan title -->
    <!-- slot menu -->
    <slot name="menuall"></slot>

    <!-- end slot menu -->
    <div
      class="allcard"
      style="
        border-top-left-radius: 0px;
        height: -webkit-fill-available;
        position: absolute;
        width: 100%;
      "
    >
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <div class="framewaitinglist">
          <div class="w-title">
            <h3>Waiting List</h3>

            <!-- <h3 v-if="countDown != 0">Countdown {{ countDown }} Detik</h3> -->
            <h3 v-if="info == null">
              Countdown
              <a-statistic-countdown
                :value="countDown"
                @finish="onFinish"
                format="HH:mm:ss"
                style="font-size: 10px !important"
              />
            </h3>
            <h3 v-else>{{ info }}</h3>
          </div>

          <a-table
            :rowKey="(record) => record.id"
            :columns="columns"
            :dataSource="data"
            :pagination="false"
            :loading="loading"
          >
            <template slot="jenis_asuransi" slot-scope="text">
              <div class="iconhealth">
                <img :src="require('@/assets/img/icon/health.svg')" />{{ text }}
              </div>
            </template>

            <template slot="periode_polis" slot-scope="text, record">
              <div class="iconcalendar">
                <img :src="require('@/assets/img/icon/calendar.svg')" />
                {{ record.periode_akhir }}
              </div>
            </template>

            <template slot="tgl_input" slot-scope="text">
              <div class="iconcalendar">
                <img :src="require('@/assets/img/icon/calendar.svg')" />
                {{ text }}
              </div>
            </template>

            <template slot="movement" slot-scope="text">
              <span
                :style="text == 'Delete' ? 'color:#00BA6A' : 'color:#D90400'"
              >
                {{ text }}
              </span>
            </template>

            <template slot="action" slot-scope="text, record">
              <div class="actiontable">
                <a-button
                  type="link"
                  @click="
                    Edit(
                      record.status_movement,
                      record.id,
                      record.nama_peserta,
                      record.id_job_order,
                      record.id_job_order_perusahaan,
                      record.tanggal_efektif,
                      record.status
                    )
                  "
                  >Edit <img :src="require('@/assets/img/icon/change.svg')"
                /></a-button>
                <a-popconfirm
                  title="Yakin data ini mau dihapus?"
                  ok-text="Ya"
                  cancel-text="Tidak"
                  @confirm="Hapus(text)"
                >
                  <a-button type="link"
                    >Hapus <img :src="require('@/assets/img/icon/delete.svg')"
                  /></a-button>
                </a-popconfirm>
              </div>
            </template>
            <template slot="footer">
              {{ null }}
            </template>
          </a-table>
        </div>

        <div class="framewaitinglist" id="batch">
          <div class="w-title">
            <h3>
              Batch
              <a-tooltip placement="topLeft">
                <div slot="title">
                  Daftar data peserta yang sedang diproses, maupun di approve.
                </div>
                <a-icon type="info-circle"
              /></a-tooltip>
            </h3>

            <div>
              <a-input-search
                placeholder="Input disini"
                style="width: 327px"
                @search="onSearch"
                allowClear
              />
            </div>
          </div>

          <a-table
            :rowKey="(record) => record.id"
            :columns="columnsBatch"
            :dataSource="dataBatch"
            :pagination="false"
            :loading="loadingGroup"
          >
            <template slot="setatus">
              Status
              <a-button type="link" @click="ModalStatus"
                ><a-icon type="info-circle"
              /></a-button>
            </template>
            <template slot="periode_polis" slot-scope="text, record">
              <div class="iconcalendar">
                <img :src="require('@/assets/img/icon/calendar.svg')" />
                {{ record.periode_akhir_format }}
              </div>
            </template>
            <template slot="jenis_asuransi" slot-scope="text">
              <div class="iconhealth">
                <img :src="require('@/assets/img/icon/health.svg')" />{{ text }}
              </div>
            </template>
            <template slot="tgl_upload" slot-scope="text, record">
              <div class="iconcalendar">
                <img :src="require('@/assets/img/icon/calendar.svg')" />
                {{ record.tanggal_upload }}
              </div>
            </template>

            <template slot="nama_group" slot-scope="text, record">
              <router-link
                :to="{
                  name: 'ListDaftarPT',
                  params: { id: record.id_job_order },
                }"
              >
                {{ text }} <a-icon type="right" />
              </router-link>
            </template>

            <template slot="uid" slot-scope="text">
              <span :ref="text">{{ text }}</span>
            </template>

            <template slot="status" slot-scope="text, record">
              <div
                class="iconverifikasi"
                v-if="record.status_batch == 'Menunggu Verifikasi'"
              >
                <img
                  :src="require('@/assets/img/icon/verification-dm.svg')"
                />{{ record.status_batch }}
              </div>

              <div
                class="iconverifikasi iconhold"
                v-if="record.status_batch == 'Hold'"
              >
                <img :src="require('@/assets/img/icon/hold-dm.svg')" />{{
                  record.status_batch
                }}
              </div>

              <div
                class="iconverifikasi iconapproved"
                v-if="record.status_batch == 'Approved'"
              >
                <img :src="require('@/assets/img/icon/approve-dm.svg')" />{{
                  record.status_batch
                }}
              </div>

              <div
                class="iconverifikasi iconsdp"
                v-if="record.status_batch == 'Sedang Dalam Proses'"
              >
                <img :src="require('@/assets/img/icon/on-proses-dm.svg')" />{{
                  record.status_batch
                }}
              </div>
              <div
                class="iconverifikasi iconsrjc"
                v-if="record.status_batch == 'Dibatalkan'"
              >
                <img :src="require('@/assets/img/icon/reject-dm.svg')" />{{
                  record.status_batch
                }}
              </div>
            </template>

            <template slot="download" slot-scope="text, record">
              <a-button type="download" @click="Download(text, record.uid)"
                >Download <img :src="require('@/assets/img/icon/download.svg')"
              /></a-button>
            </template>
            <template slot="footer">
              {{ null }}
            </template>
          </a-table>
          <div class="paginationcustom" style="margin-top: 0px !important">
            <a-pagination
              showSizeChanger
              @showSizeChange="onShowSizeChange"
              :pageSizeOptions="pageSizeOption"
              :pageSize.sync="pageSize"
              :total="total"
              v-model="page"
              :showTotal="
                (total, range) =>
                  ` ${range[0]}-${range[1]}  of ${
                    pagination.total == undefined ? '0' : pagination.total
                  } `
              "
              @change="handlePageChange"
            >
              <template slot="buildOptionText" slot-scope="props">
                <span v-if="props.value !== '1000000'">
                  {{ props.value }} / page
                </span>
                <span v-if="props.value == '1000000'">All</span>
              </template>
            </a-pagination>
          </div>
        </div>
      </simplebar>
    </div>
    <ModalAction
      ref="actions"
      @fetch="
        fetch({
          page: 1,
          pageSizes: 15,
        })
      "
    />
    <ModalStatus ref="action" />
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import ModalAction from "@/components/Modal/actionQue.vue";
import ModalStatus from "@/components/Modal/StatusMovement.vue";
import moment from "moment";

const columns = [
  {
    title: "Nama Peserta",
    dataIndex: "nama_peserta",
  },
  {
    title: "Nama Karyawan",
    dataIndex: "nama_karyawan",
  },
  {
    title: "Status",
    dataIndex: "status",
  },

  {
    title: "Periode Polis",
    dataIndex: "periode_polis",
    scopedSlots: { customRender: "periode_polis" },
  },
  {
    title: "Jenis Asuransi",
    dataIndex: "jenis_asuransi",
    align: "center",
    scopedSlots: { customRender: "jenis_asuransi" },
  },
  {
    title: "Tgl Input",
    dataIndex: "tanggal_input_format",
    scopedSlots: { customRender: "tgl_input" },
  },
  {
    title: "Status Movement",
    dataIndex: "status_movement",
    align: "center",
    scopedSlots: { customRender: "movement" },
  },
  {
    title: "Action",
    dataIndex: "id",
    scopedSlots: { customRender: "action" },
    width: 250,
    align: "center",
  },
];

const columnsBatch = [
  {
    title: "Batch Number",
    dataIndex: "uid",
    scopedSlots: { customRender: "uid" },
  },
  {
    title: "Nama Group",
    dataIndex: "nama_group",
    scopedSlots: { customRender: "nama_group" },
  },
  {
    title: "Periode Polis",
    dataIndex: "periode_polis",
    scopedSlots: { customRender: "periode_polis" },
  },
  {
    title: "Jenis Asuransi",
    dataIndex: "jenis_asuransi",
    scopedSlots: { customRender: "jenis_asuransi" },
    align: "center",
  },
  {
    title: "Tgl Upload",
    dataIndex: "tgl_upload",
    scopedSlots: { customRender: "tgl_upload" },
  },

  {
    dataIndex: "status",
    scopedSlots: { customRender: "status", title: "setatus" },
    align: "center",
    width: 150,
  },
  {
    title: "Download",
    dataIndex: "id",
    scopedSlots: { customRender: "download" },
    align: "center",
    width: 150,
  },
];

export default {
  components: {
    ModalAction,
    simplebar,
    ModalStatus,
  },
  data() {
    return {
      columns,
      columnsBatch,
      loading: false,
      loadingGroup: false,
      time: "14:00:00",
      interval: 30,
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 0,
      page: 1,
      current: 1,
      pageSize: 15,
      showTotal: "",

      dataBatch: [],
      data: [],
      countDown: null,
      count_down_timer: null,
      info: null,
      breadcrumb: [
        {
          name: "Antrian Data",
          nameroute: "",
          param: {},
        },
      ],
    };
  },
  sockets: {
    connect: function () {
      console.log("socket nyambung");
    },

    disconnect() {
      console.log("dc");
    },

    EVENT: function (val) {
      if (val.type == "COUNTDOWN-BATCH") {
        this.checkTime();
        this.fetch({
          page: 1,
          pageSizes: 15,
        });

        this.fetchGroup({
          page: 1,
          pageSizes: 15,
        });
      }

      if (val.type == "PROCESS-BATCH") {
        this.fetch({
          page: 1,
          pageSizes: 15,
        });
      }
    },
  },

  created() {
    // this.countDownTimer();
  },
  mounted() {
    //console.log(new Date("2021-06-16").getTime());
    document.body.classList.remove("poopersbody");
    this.checkTime();
    this.fetch({
      page: 1,
      pageSizes: 15,
    });

    this.fetchGroup({
      page: 1,
      pageSizes: 15,
    });
  },
  watch: {
    $route(to, from) {
      this.checkTime();
      this.fetch({
        page: 1,
        pageSizes: 15,
      });

      this.fetchGroup({
        page: 1,
        pageSizes: 15,
      });
    },
  },
  // watch: {
  //   countDown: {
  //     handler: function (item) {
  //       if (item == 0) {
  //         this.countDownTimer();
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    moment,
    // clicks(item) {
    //   console.log(this.$refs);
    //   this.$refs[item].scrollIntoView({
    //     behavior: "smooth",
    //   });
    // },
    ModalStatus() {
      this.$refs.action.Show();
    },
    Download(id, uid) {
      this.$store.dispatch("DownloadBatch", {
        id: id,
        uid: uid,
      });
    },
    Edit(item, id, nama, idjoborder, idperusahaan, tgl_efektif, status) {
      //console.log(item);
      if (item != "CHANGE") {
        this.$refs.actions.Show(
          item,
          id,
          nama,
          idjoborder,
          idperusahaan,
          tgl_efektif,
          status
        );
      }

      if (item == "CHANGE" || item == "ADD") {
        this.$router.push({
          name: "EditPesertaTemp",
          params: { id: id },
          query: {
            id_job_order: idjoborder,
            movement: item,
          },
        });
      }
    },
    checkTime() {
      this.$store.dispatch("GetCheckTime").then((response) => {
        //console.log(response.data.data);

        let endTime = new Date(
          new Date().getTime() +
            moment(response.data.data.start_time, "HH:mm:ss").unix()
        );

        var now = new Date();
        now.setSeconds(now.getSeconds() + response.data.data.interval_second); // timestamp
        now = new Date(now); // Date object

        //console.log("ora iso", moment(now, "HH:mm:ss").unix());

        if (response.data.data.info == null) {
          // this.countDown =
          //   moment(response.data.data.start_time, "HH:mm:ss").unix() * 1000 +
          //   1000 * 60 * response.data.data.interval;
          this.countDown = moment(now, "HH:mm").unix() * 1000;
        } else {
          this.info = response.data.data.info;
        }
      });
    },
    // DocountDownTimer() {
    //   if (this.countDown > 0) {
    //     this.count_down_timer = setTimeout(() => {
    //       this.countDown--;
    //       this.DocountDownTimer();
    //     }, 1000);
    //   }
    // },
    // countDownTimer() {
    //   this.$store.dispatch("GetCountDown").then((response) => {
    //     //countdown_second
    //     if (response.data.data.countdown_second != undefined) {
    //       this.countDown = response.data.data.countdown_second;
    //       this.DocountDownTimer();
    //     } else {
    //       this.countDown = 0;
    //       this.info = response.data.data.info;
    //     }
    //   });
    // },
    Hapus(id) {
      this.loading = true;
      this.$store
        .dispatch("HapusDataAntrian", {
          id: id,
        })
        .then((response) => {
          this.$notification.success({
            message: response.data.message,
          });

          this.fetch({
            page: 1,
            pageSizes: 15,
          });

          this.loading = false;
        });
    },
    onFinish() {
      console.log("finished!");
    },
    fetch(params = {}) {
      this.loading = true;
      this.$store
        .dispatch("GetWaitingList", {
          ...params,
        })
        .then((response) => {
          this.loading = false;
          this.data = response.data.data;
        });
    },

    fetchGroup(params = {}) {
      this.loadingGroup = true;
      this.$store
        .dispatch("GetGroupAntrianData", {
          ...params,
        })
        .then((response) => {
          this.loadingGroup = false;
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          pagination.pageSize = response.data.pageSize;
          pagination.page = response.data.page;

          this.total = pagination.total;
          this.dataBatch = response.data.data;
          this.pagination = pagination;
          this.model = [];
        });
    },

    onShowSizeChange(current, pageSize) {
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;
      this.fetchGroup({
        page: this.page,
        pageSizes: this.pageSize,
        search: this.search,
        ...params,
      });
      this.handlePageChange(current);
    },
    handlePageChange(page) {
      //checklist reset
      const params = this.paramssearch;
      const pager = { ...this.pagination };
      pager.current = page;

      this.fetchGroup({
        page: pager.current,
        pageSizes: pager.pageSize,
        ...params,
      });
    },

    handleTableChange(page, filter, sorter) {
      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;

      this.pagination = pager;

      console.log(pager);
      this.fetchGroup({
        page: pager.page,
        pageSizes: pager.pageSize,
        search: this.search,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...params,
      });
    },

    onSearch(value) {
      (this.search = value),
        this.fetchGroup({
          page: 1,
          pageSizes: this.pageSize,
          search: value,
        });
    },
  },
};
</script>
