<template>
  <div>
    <!-- Start Header -->
    <div class="ContentHeader">
      <div class="HeaderAll">
        <!-- Slot Breadcrumb -->
        <slot name="titlebreadcrumb"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <!-- Slot Benefit Header-->
      <slot name="benefitheader"></slot>
    </div>
    <!-- End Header -->

    <!-- slot menu -->
    <slot name="menuall"></slot>

    <div
      class="allcard"
      style="
        border-top-left-radius: 0px;
        height: -webkit-fill-available;
        position: absolute;
        width: 100%;
      "
    >
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <!-- start content here -->
        <slot name="menudatapeserta" />

        <!-- advanced search -->
        <div style="display: flex; justify-content: space-between">
          <div class="leftsearch">
            <div v-if="$route.query.menu == undefined">
              <a-button type="linkcustom" @click="importdatapeserta"
                >Import Data Peserta
                <img :src="require('@/assets/img/icon/plus.svg')"
              /></a-button>
              <a-button type="linkcustom" @click="tambahpeserta"
                >Tambah <img :src="require('@/assets/img/icon/peserta.svg')"
              /></a-button>

              <a-dropdown
                :trigger="['click']"
                v-if="$route.query.menu == undefined"
              >
                <a-button type="linkcustom"
                  >Export <a-icon type="down"
                /></a-button>

                <a-menu slot="overlay">
                  <a-menu-item key="1" @click="exportall"> All </a-menu-item>
                  <a-menu-item key="2" :disabled="enableds" @click="exportdata">
                    Selected item
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
          <div class="ContentHeaderSearch searchpeserta">
            <!-- <span v-if="$route.query.nama_perusahaan != undefined">
              <label
                style="display: block; margin-bottom: -10px; font-size: 11px"
                >Nama perusahaan:</label
              >
              <a-tag color="blue">
                {{ $route.query.nama_perusahaan }}
              </a-tag></span
            > -->
            <a-input-search
              placeholder="Search Here"
              style="width: 327px"
              @search="onSearch"
              allowClear
              v-model="search"
            />

            <a-button
              type="advancedsearch"
              ref="buttonadvanced"
              @click="advanced"
              >Advanced Search <a-icon type="filter" />
            </a-button>
            <div
              class="advancedsearch-dropdown"
              :style="advancedsearch ? 'display: block;' : 'display: none;'"
              @click="closeadvance"
            >
              <div class="_dropdownwraper" :style="{ top: top + 'px' }">
                <div class="button-right">
                  <a-button
                    type="link"
                    @click="advanced"
                    style="right: -20px !important"
                  >
                    <span class="ant-modal-close-x">
                      <a-icon type="close" /></span
                  ></a-button>
                </div>
                <a-form :form="form" @submit="Submit">
                  <a-row :gutter="[15, 15]">
                    <a-col :lg="4">
                      <a-form-item label="Nama Peserta">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'nama_pasien',
                            {
                              initialValue:
                                $route.query.nama_pasien != undefined
                                  ? $route.query.nama_pasien
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :lg="4">
                      <a-form-item label="Tgl Lahir">
                        <a-range-picker
                          autocomplete="off"
                          v-decorator="['tanggal_lahir']"
                          style="width: 100%"
                          format="DD MMM YYYY"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :lg="4">
                      <a-form-item label="Jenis Kelamin">
                        <a-select
                          autocomplete="off"
                          v-decorator="[
                            'jenis_kelamin',
                            {
                              initialValue:
                                $route.query.jenis_kelamin != undefined
                                  ? $route.query.jenis_kelamin
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        >
                          <a-select-option value="Pria">Pria</a-select-option>
                          <a-select-option value="Wanita"
                            >Wanita</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="Nama Karyawan">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'nama_karyawan',
                            {
                              initialValue:
                                $route.query.nama_karyawan != undefined
                                  ? $route.query.nama_karyawan
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="NIK">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'nik',
                            {
                              initialValue:
                                $route.query.nik != undefined
                                  ? $route.query.nik
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="Unique ID">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'unique_id',
                            {
                              initialValue:
                                $route.query.unique_id != undefined
                                  ? $route.query.unique_id
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="No.Peserta">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'no_peserta',
                            {
                              initialValue:
                                $route.query.no_peserta != undefined
                                  ? $route.query.no_peserta
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="No.Kartu">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'no_kartu',
                            {
                              initialValue:
                                $route.query.no_kartu != undefined
                                  ? $route.query.no_kartu
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="Status">
                        <a-select
                          autocomplete="off"
                          v-decorator="[
                            'status_peserta',
                            {
                              initialValue:
                                $route.query.menu_peserta != undefined
                                  ? $route.query.menu_peserta
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        >
                          <a-select-option value="Employee"
                            >Employee</a-select-option
                          >
                          <a-select-option value="Spouse"
                            >Spouse</a-select-option
                          >
                          <a-select-option value="Child">Child</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="Plan RI">
                        <a-input
                          autocomplete="off"
                          v-decorator="[
                            'ri',
                            {
                              initialValue:
                                $route.query.ri != undefined
                                  ? $route.query.ri
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="Tgl Efektif">
                        <a-range-picker
                          autocomplete="off"
                          v-decorator="[
                            'tgl_efektif',
                            {
                              initialValue:
                                $route.query.tgl_efektif != undefined
                                  ? $route.query.tgl_efektif
                                  : null,
                            },
                          ]"
                          style="width: 100%"
                          format="DD MMM YYYY"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :lg="4">
                      <a-form-item label="Movement">
                        <a-select
                          autocomplete="off"
                          v-decorator="[
                            'status_movement',
                            {
                              initialValue:
                                $route.query.menu_movement != undefined
                                  ? $route.query.menu_movement
                                  : null,
                            },
                          ]"
                          placeholder="Input disini"
                        >
                          <a-select-option value="AWAL">AWAL</a-select-option>
                          <a-select-option value="CHANGE"
                            >CHANGE</a-select-option
                          >
                          <a-select-option value="DELETE"
                            >DELETE</a-select-option
                          >
                          <a-select-option value="REAKTIF"
                            >REAKTIF</a-select-option
                          >
                          <a-select-option value="REPRINT"
                            >REPRINT</a-select-option
                          >
                          <a-select-option value="MUTASI"
                            >MUTASI</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :lg="24">
                      <div class="button-right">
                        <a-button type="default" @click="Reset">Reset</a-button>
                        <a-button type="primary" html-type="submit"
                          >Cari</a-button
                        >
                      </div>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </div>

            <div class="butonright">
              <span v-if="$route.query.menu == undefined">
                <a-button
                  type="linkcustom"
                  :disabled="enableds"
                  @click="actionall('Reprint')"
                  >Reprint
                  <img :src="require('@/assets/img/icon/reprint.svg')" />
                </a-button>
                <a-button
                  type="linkcustom"
                  :disabled="enableds"
                  @click="actionall('Mutasi')"
                  >Mutasi <img :src="require('@/assets/img/icon/mutasi.svg')"
                /></a-button>
                <a-button
                  type="linkcustom"
                  :disabled="enableds"
                  @click="actionall('Delete')"
                  >Delete <img :src="require('@/assets/img/icon/delete.svg')"
                /></a-button>
              </span>
              <span
                v-if="
                  $route.query.menu == 'nonaktif' ||
                  $route.query.menu == 'coming-movement'
                "
              >
                <a-button
                  type="linkcustom"
                  :disabled="enableds"
                  @click="actionall('Reaktif')"
                  >Reaktif
                  <img :src="require('@/assets/img/icon/reaktif.svg')" />
                </a-button>
              </span>
            </div>
          </div>
        </div>
        <!-- end advanced search -->

        <!-- Start Table Upload Failed -->
        <a-table
          :rowKey="(record) => record.id"
          :columns="col"
          :dataSource="data"
          :pagination="false"
          :scroll="{ x: widthtable }"
          :loading="loading"
          @change="handleTableChange"
        >
          <div slot="filterDropdown" slot-scope="{ column }">
            <div class="wrapbuttonsort">
              <a-button
                block
                type="link"
                @click="
                  handlesTbl('nama_peserta', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'nama_peserta'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Nama Peserta
                  </div>
                </div>
              </a-button>

              <a-button
                block
                type="link"
                @click="
                  handlesTbl('tanggal_lahir', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'tanggal_lahir'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Tgl Lahir
                  </div>
                </div>
              </a-button>

              <a-button
                type="link"
                @click="
                  handlesTbl('jenis_kelamin', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'jenis_kelamin'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Jenis Kelamin
                  </div>
                </div>
              </a-button>
            </div>
          </div>

          <div
            slot="filterIcon"
            slot-scope="filtered, column"
            class="customSorter"
          >
            <a-icon
              type="caret-up"
              :style="{
                color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
              }"
            />

            <a-icon
              type="caret-down"
              :style="{
                color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
              }"
            />
          </div>
          <template slot="checkTitle">
            <a-checkbox v-model="checkeds" @change="isCheckAll" />
          </template>
          <template slot="SelectAll" slot-scope="text, record, index">
            <a-checkbox
              v-model="model[index]"
              :value="text"
              @change="isDeselect"
              v-if="record.coming_movement_flag > 0 ? false : true"
            />
          </template>
          <template slot="nama_pasien" slot-scope="text, record">
            <div class="iconnamapasien">
              <div>
                <router-link
                  :to="{
                    name: 'DetailPeserta',
                    params: { id: $route.params.id, idpeserta: record.id },
                    query: { status: $route.query.menu },
                  }"
                  >{{ record.nama_peserta }} <a-icon type="right"
                /></router-link>
              </div>
              <span class="iconcalendarblack"
                ><img :src="require('@/assets/img/icon/dateblack.svg')" />{{
                  record.tanggal_lahir
                }}</span
              >
              <span class="iconcalendar"
                ><img
                  :src="
                    require(`@/assets/img/icon/${record.jenis_kelamin}.svg`)
                  "
                />
                {{ record.jenis_kelamin }}</span
              >
            </div>
          </template>
          <template slot="nama_karyawan" slot-scope="text, record">
            <router-link
              :to="{
                name: 'DetailPeserta',
                params: {
                  id: $route.params.id,
                  idpeserta: record.id_karyawan_self,
                },
                query: { status: $route.query.menu },
              }"
              >{{ text }} <a-icon type="right"
            /></router-link>
          </template>
          <template slot="nik" slot-scope="text, record">
            <div>{{ text }}</div>
            <div>{{ record.unique_id }}</div>
          </template>

          <template slot="plan" slot-scope="text">
            <div class="iconpeserta">
              <img :src="require('@/assets/img/icon/ri.svg')" />RI - {{ text }}
            </div>
          </template>

          <template slot="no_peserta" slot-scope="text, record">
            <div>{{ text }}</div>
            <div>{{ record.no_kartu }}</div>
          </template>

          <template slot="status_movement" slot-scope="text">
            <span
              :style="
                text == 'DELETE'
                  ? 'color:#D90400'
                  : text == 'AWAL'
                  ? 'color:#0024E2'
                  : text == 'CHANGE'
                  ? 'color:#B261F0'
                  : text == 'REAKTIF'
                  ? 'color:#EC832F'
                  : 'color:#00E077'
              "
              >{{ text }}</span
            >
          </template>

          <template slot="action" slot-scope="text, record">
            <a-dropdown
              :trigger="['click']"
              placement="bottomRight"
              v-if="
                $route.query.menu == undefined ||
                $route.query.menu == 'nonaktif' ||
                ($route.query.menu == 'coming-movement' &&
                  record.status_movement == 'DELETE')
              "
            >
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                <span class="iconmmore">
                  <a-icon type="more" />
                </span>
              </a>
              <div slot="overlay" class="overlaymenumore">
                <div class="wrapoverlaymore">
                  <div class="submenumore">
                    <div v-if="$route.query.menu != 'coming-movement'">
                      <router-link
                        :to="{
                          name: 'DetailPeserta',
                          params: {
                            id: $route.params.id,
                            idpeserta: record.id,
                          },
                        }"
                        >View <img :src="require('@/assets/img/icon/eye.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.menu == undefined">
                      <router-link
                        :to="{
                          name: 'EditPeserta',
                          params: {
                            id: $route.params.id,
                            idpeserta: record.id,
                          },
                        }"
                        >Edit <img :src="require('@/assets/img/icon/edit.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.menu == undefined">
                      <router-link
                        to="#"
                        @click.native="
                          modal(
                            'Delete',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Delete
                        <img :src="require('@/assets/img/icon/delete.svg')"
                      /></router-link>
                    </div>
                    <div
                      v-if="
                        $route.query.menu == 'nonaktif' ||
                        ($route.query.menu == 'coming-movement' &&
                          record.status_movement == 'DELETE')
                      "
                    >
                      <router-link
                        :to="{
                          query: {
                            menu: $route.query.menu,
                          },
                        }"
                        @click.native="
                          modal(
                            'Reaktif',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Reaktif
                        <img :src="require('@/assets/img/icon/reaktif.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.menu == undefined">
                      <router-link
                        to="#"
                        @click.native="
                          modal(
                            'Reprint',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Reprint
                        <img :src="require('@/assets/img/icon/reprint.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.menu == undefined">
                      <router-link
                        to="#"
                        @click.native="
                          modal(
                            'Mutasi',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Mutasi
                        <img :src="require('@/assets/img/icon/mutasi.svg')"
                      /></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </a-dropdown>
          </template>
          <template slot="footer">
            {{ null }}
          </template>
        </a-table>
        <!-- end content here -->

        <!-- pagination -->
        <div class="paginationcustom" style="margin-top: 0px !important">
          <a-pagination
            showSizeChanger
            @showSizeChange="onShowSizeChange"
            :pageSizeOptions="pageSizeOption"
            :pageSize.sync="pageSize"
            :total="total"
            v-model="page"
            :showTotal="
              (total, range) =>
                ` ${range[0]}-${range[1]}  of ${
                  pagination.total == undefined ? '0' : pagination.total
                } `
            "
            @change="handlePageChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '1000000'">
                {{ props.value }} / page
              </span>
              <span v-if="props.value == '1000000'">All</span>
            </template>
          </a-pagination>
        </div>
        <!-- end pagination -->
      </simplebar>
    </div>

    <ModalImportDataPeserta ref="importData" />
    <ModalAction ref="action" />
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import ModalAction from "@/components/Modal/action.vue";
import ModalImportDataPeserta from "@/components/Modal/importDataPeserta.vue";
const columns = [
  {
    slots: { title: "checkTitle" },
    dataIndex: "id",
    scopedSlots: { customRender: "SelectAll" },
    width: 50,
    fixed: "left",
  },
  {
    title: "Nama peserta, Tgl Lahir, Jenis Kelamin",
    dataIndex: "nama_pasien",
    scopedSlots: {
      customRender: "nama_pasien",
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
    },
    customSort: "",
    choseSort: "",
    width: 300,
  },
  {
    title: "Nama Karyawan",
    dataIndex: "nama_karyawan",
    scopedSlots: { customRender: "nama_karyawan" },
    sorter: true,
    width: 200,
  },
  {
    title: "NIK & Unique ID",
    dataIndex: "nik",
    scopedSlots: { customRender: "nik" },
    sorter: true,
    width: 200,
  },
  {
    title: "No.Peserta & No.Kartu",
    dataIndex: "no_peserta",
    scopedSlots: { customRender: "no_peserta" },
    sorter: true,
    width: 200,
  },
  {
    title: "Status",
    dataIndex: "status_peserta",
    scopedSlots: { customRender: "status" },
    sorter: true,
    align: "center",
    width: 200,
  },
  {
    title: "Plan RI",
    dataIndex: "ri",
    scopedSlots: { customRender: "plan" },
    sorter: true,
    width: 200,
  },
  {
    title: "Tgl Efektif",
    dataIndex: "tgl_efektif",
    sorter: true,
    width: 150,
  },
  {
    title: "Status Movement",
    dataIndex: "status_movement",
    sorter: true,
    scopedSlots: { customRender: "status_movement" },
    align: "center",
    fixed: "right",
    width: 150,
  },
  {
    title: "Action",
    scopedSlots: { customRender: "action" },
    align: "center",
    fixed: "right",
    width: 100,
  },
];
export default {
  // props: {
  // },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormAdvancedSearch" });
  },
  components: {
    ModalImportDataPeserta,
    ModalAction,
    simplebar,
  },
  data() {
    return {
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 0,
      page: 1,
      current: 1,
      pageSize: 15,
      loading: false,
      data: [],

      columns,
      search:
        this.$route.query.search != undefined ? this.$route.query.search : "",
      advancedsearch: false,
      checkAll: [],
      checkeds: false,
      paramssearch:
        this.$route.query.page != undefined &&
        this.$route.query.pageSizes != undefined
          ? Object.keys(this.$route.query).reduce((obj, key) => {
              if (key !== "page" && key !== "pageSizes") {
                obj[key] = this.$route.query[key];
              }

              return obj;
            }, {})
          : {},
      enableds: true,
      model: [],
      click: 0,
      top: 0,
      labels: "",
      heighttable: 0,
      widthtable: 0,
      breadcrumb: [
        {
          name: "Daftar Group",
          nameroute: "ListDaftarGroup",
          param: {},
        },
        {
          name: "Data Peserta",
          nameroute: "",
          param: {},
        },
      ],
    };
  },
  computed: {
    contactID() {
      return this.$store.state.Auth.data.user.id_contact;
    },
    col() {
      // return this.$route.query.menu == "coming-movement"
      //   ? columns.filter((key) => key.title != "Action")
      //   : columns;
      return columns;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    const search = this.search != "" ? { search: this.search } : {};
    const params = this.paramssearch != undefined ? this.paramssearch : {};

    // this.fetch({
    //   page: 1,
    //   pageSizes: this.pageSize,
    //   id: this.$route.params.id,
    //   status_movements: this.$route.query.menu,
    //   id_perusahaan: this.$route.query.id_perusahaan,
    //   ...search,
    //   ...params,
    // });

    this.pageSize =
      this.$route.query.pageSizes != undefined
        ? parseInt(this.$route.query.pageSizes)
        : 15;
    this.page =
      this.$route.query.page != undefined
        ? parseInt(this.$route.query.page)
        : 1;
  },
  watch: {
    "$route.query": {
      handler: function (query, oldquery) {
        if (query.search != undefined) {
          this.search = query.search;
        } else {
          this.search = "";
        }

        if (oldquery != undefined) {
          if (query.menu != oldquery.menu) {
            this.form.resetFields();
            this.page = 1;
            this.pageSize = 15;
          }
        }

        this.fetch({
          page: this.page,
          pageSizes: this.pageSize,
          id: this.$route.params.id,
          ...query,
        });

        this.checkeds = false;
        this.enableds = true;
      },
      deep: true,
      immediate: true,
    },

    pageSize: {
      handler(value) {
        this.pageSize = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleResize() {
      // this.heighttable = screen.height - 805;
      this.heighttable = screen.height;
      this.widthtable = screen.width - 2006;
    },
    actionall(e) {
      //alert(e);
      const id = this.model
        .map((i, index) => (i == true ? this.data[index].id : ""))
        .filter((keys) => keys != "");

      const namapeserta = this.model
        .map((i, index) => (i == true ? this.data[index].nama_peserta : ""))
        .filter((keys) => keys != "");

      const idperusahaan = this.model
        .map((i, index) =>
          i == true ? this.data[index].id_job_order_perusahaan : ""
        )
        .filter((keys) => keys != "");

      const status = this.model
        .map((i, index) => (i == true ? this.data[index].status : ""))
        .filter((keys) => keys != "");

      const status_movement = this.model
        .map((i, index) => (i == true ? this.data[index].status_movement : ""))
        .filter((keys) => keys != "");

      // this.$store.dispatch("ExportBatch", {
      //   data: check.filter((keys) => keys !== ""),
      // });
      //console.log(idperusahaan);

      if (e == "Mutasi") {
        console.log(status);

        if (this.allAreEqual(idperusahaan) == true) {
          //validasi status harus employee
          if (status.every((e) => (e == "E") == true)) {
            this.modal(e, id, namapeserta, idperusahaan[0]);
          } else {
            this.$error({
              title: "Warning",
              content: "Mutasi hanya bisa dilakukan untuk status Employee",
            });
          }
        } else {
          this.$error({
            title: "Warning",
            content:
              "Mutasi hanya bisa dilakukan dengan nama perusahaan yang sama",
          });
        }
      } else {
        if (e == "Reaktif" && this.$route.query.menu == "coming-movement") {
          if (status_movement.every((e) => (e == "DELETE") == true)) {
            this.modal(e, id, namapeserta, idperusahaan[0]);
          } else {
            this.$error({
              title: "Warning",
              content:
                "Reaktif hanya bisa dilakukan untuk status movement DELETE",
            });
          }
        } else {
          this.modal(e, id, namapeserta, idperusahaan);
        }
      }
    },

    exportall() {
      this.loading = true;
      this.$store
        .dispatch("ExportAllPeserta", {
          id_job_order: this.$route.params.id,
        })
        .then((response) => {
          this.loading = false;

          this.uncheckAll();
        })
        .catch((err) => {
          this.loading = false;

          this.uncheckAll();
        });
    },

    exportdata() {
      const id = this.model
        .map((i, index) => (i == true ? this.data[index].id : ""))
        .filter((keys) => keys != "");

      const id_job_order = this.data[0].id_job_order;
      this.loading = true;
      this.$store
        .dispatch("ExportDataPeserta", {
          id_job_order: id_job_order,
          id: id,
        })
        .then((response) => {
          this.loading = false;

          this.uncheckAll();
        })
        .catch((err) => {
          this.loading = false;
          this.uncheckAll();
        });

      //console.log(id_job_order);
    },

    uncheckAll() {
      this.model = [];
      this.checkeds = false;
      this.enableds = true;
    },
    allAreEqual(array) {
      const result = array.every((element) => {
        if (element === array[0]) {
          return true;
        }
      });

      return result;
    },

    modal(item, id, namapeserta, idperusahaan) {
      if (item == "Mutasi") {
        //console.log(this.data.filter())
        //console.log(id);
        if (typeof id == "object") {
          this.$refs.action.Show(item, id, namapeserta, idperusahaan);
        } else {
          if (
            this.data[this.data.map((key) => key.id).indexOf(id)].status == "E"
          ) {
            this.$refs.action.Show(item, id, namapeserta, idperusahaan);
          } else {
            //this.$message.error("Mutasi hanya untuk status Employee");

            this.$error({
              title: "Warning",
              content: "Mutasi hanya untuk status Employee",
            });
          }
        }
      } else {
        this.$refs.action.Show(item, id, namapeserta, idperusahaan);
      }
    },
    isDeselect(e) {
      this.checkeds = false;
      if (this.model == []) {
        this.enableds = true;
      } else {
        if (this.model.filter((keys) => keys == true).length == 0) {
          this.enableds = true;
        } else {
          this.enableds = false;
        }
      }

      if (this.model.length == this.data.length) {
        this.checkeds = true;
      }

      if (
        this.model.filter((keys) => keys == true).length != this.data.length
      ) {
        this.checkeds = false;
      } else {
        this.checkeds = true;
      }

      //console.log(this.model,'',this.model.filter((keys) => keys == true).length)
    },
    isCheckAll(e) {
      Object.assign(this, {
        model: e.target.checked ? this.data.map((i) => true) : [],
      });

      this.enableds = e.target.checked ? false : true;

      // console.log(this.model.length);
    },

    tambahpeserta() {
      this.$router.push({ name: "TambahPeserta" });
    },
    importdatapeserta() {
      this.$refs.importData.Show();
    },
    Reset() {
      this.form.resetFields();
      this.advancedsearch = false;
      const menu =
        this.$route.query.menu != undefined
          ? { menu: this.$route.query.menu }
          : {};

      const id_perusahaan =
        this.$route.query.id_perusahaan != undefined
          ? {
              id_perusahaan: this.$route.query.id_perusahaan,
              nama_perusahaan: this.$route.query.nama_perusahaan,
            }
          : {};

      this.$router.push({
        query: {
          page: 1,
          pageSizes: this.pageSize,
          ...id_perusahaan,
          ...menu,
        },
      });
    },
    Submit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          //this.advancedsearch = false;
          // this.fetch({
          //   page: 1,
          //   pageSizes: this.pageSize,
          //   id: this.$route.params.id,
          //   status_movements: this.$route.query.menu,
          //   ...values,
          // });

          const menu =
            this.$route.query.menu != undefined
              ? { menu: this.$route.query.menu }
              : {};

          const id_perusahaan =
            this.$route.query.id_perusahaan != undefined
              ? {
                  id_perusahaan: this.$route.query.id_perusahaan,
                  nama_perusahaan: this.$route.query.nama_perusahaan,
                }
              : {};

          this.$router.push({
            query: {
              page: 1,
              pageSizes: this.pageSize,
              ...id_perusahaan,
              ...values,
              ...menu,
            },
          });
          this.paramssearch = values;
          this.advancedsearch = false;
        }
      });
    },
    onShowSizeChange(current, pageSize) {
      const search = this.search != "" ? { search: this.search } : {};
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;

      if (params.search != undefined) {
        delete params.search;
      }

      this.$router.push({
        query: {
          page: 1,
          pageSizes: pageSize,
          id: this.$route.params.id,
          menu: this.$route.query.menu,
          id_perusahaan: this.$route.query.id_perusahaan,
          nama_perusahaan: this.$route.query.nama_perusahaan,
          ...search,
          ...params,
        },
      });

      //this.handlePageChange(current);
    },
    handlePageChange(page) {
      //checklist reset
      const search = this.search != "" ? { search: this.search } : {};
      const params = this.paramssearch != undefined ? this.paramssearch : {};

      const pager = { ...this.pagination };
      pager.current = page;

      if (params.search != undefined) {
        delete params.search;
      }

      // this.fetch({
      //   page: pager.current,
      //   pageSizes: pager.pageSize,
      //   status_movements: this.$route.query.menu,
      //   id_perusahaan: this.$route.query.id_perusahaan,
      //   id: this.$route.params.id,
      //   ...search,
      //   ...params,
      // });

      this.$router.push({
        query: {
          page: pager.current,
          pageSizes: pager.pageSize,
          menu: this.$route.query.menu,
          id_perusahaan: this.$route.query.id_perusahaan,
          id: this.$route.params.id,
          ...search,
          ...params,
        },
      });
    },
    handlesTbl(item, sortby) {
      if (this.labels == "") {
        this.labels = item;
      }

      if (this.labels != item) {
        this.labels = item;
        this.click = 0;
      }

      console.log("labels", this.labels);
      this.click += 1;

      if (this.click == 3) {
        this.click = 0;
      }

      let sorting = {
        field: item,
        order: sortby[this.click],
      };
      this.handleTableChange(this.page, "", sorting);
      if (
        item == "nama_peserta" ||
        item == "tanggal_lahir" ||
        item == "jenis_kelamin"
      ) {
        this.columns[1].customSort = sortby[this.click];
        this.columns[1].choseSort = item;
      }

      // if (item == "tanggal_keluar" || item == "tanggal_masuk") {
      //   this.columns[5].customSort = sortby[this.click];
      //   this.columns[5].choseSort = item;
      // }

      //set columnya ke sorter

      //console.log(this.columns[1].choseSort);
    },
    handleTableChange(page, filter, sorter) {
      if (
        sorter.field != "nama_peserta" ||
        sorter.field != "tanggal_lahir" ||
        sorter.field != "jenis_kelamin"
      ) {
        this.columns[1].customSort = "";
      }

      const pager = { ...this.pagination };
      const search = this.search != "" ? { search: this.search } : {};
      const params = this.paramssearch != undefined ? this.paramssearch : {};
      pager.current = page;
      // this.fetch({
      //   page: pager.current,
      //   pageSizes: pager.pageSize,
      //   id: this.$route.params.id,
      //   status_movements: this.$route.query.menu,
      //   id_perusahaan: this.$route.query.id_perusahaan,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...search,
      //   ...params,
      // });

      if (params.search != undefined) {
        delete params.search;
      }

      this.$router.push({
        page: pager.current,
        pageSizes: pager.pageSize,
        id: this.$route.params.id,
        menu: this.$route.query.menu,
        id_perusahaan: this.$route.query.id_perusahaan,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...search,
        ...params,
      });
    },
    advanced(e) {
      //console.log(this.$refs.buttonadvanced.$el.offsetTop);

      this.advancedsearch = !this.advancedsearch;
      this.top = this.$refs.buttonadvanced.$el.offsetTop + 305;
    },

    closeadvance(e) {
      if (e.target._prevClass == "advancedsearch-dropdown") {
        this.advancedsearch = false;
      }
    },
    fetch(params = {}) {
      //console.log(params);
      this.loading = true;
      this.$store
        .dispatch("getDataPeserta", {
          ...params,
        })
        .then((response) => {
          this.loading = false;
          const pagination = { ...this.pagination };
          pagination.total = response.data.total;
          pagination.pageSize = response.data.pageSize;
          pagination.page = response.data.page;

          this.total = pagination.total;

          this.data = response.data.data;
          this.pagination = pagination;

          this.model = [];
        });
    },
    onSearch(value) {
      // this.search = value;
      const search = value != "" ? { search: value } : {};
      // this.fetch({
      //   page: 1,
      //   pageSizes: this.pageSize,
      //   id: this.$route.params.id,
      //   status_movements: this.$route.query.menu,
      //   id_perusahaan: this.$route.query.id_perusahaan,
      //   ...search,
      // });

      this.$router.push({
        query: {
          page: 1,
          pageSizes: this.pageSize,
          id: this.$route.params.id,
          menu: this.$route.query.menu,
          id_perusahaan: this.$route.query.id_perusahaan,
          ...search,
        },
      });
    },
  },
};
</script>

<!-- <style>
.ant-dropdown-placement-bottomRight {
  left: 0 !important;
}
</style> -->
